import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { getSetariService, getUsersService } from "../backend/SetariService";
import ButtonComponent from "../components/basic/ButtonComponent";

import { updateSetariService } from "../backend/SetariService";
import Dispeceri from "./Dispeceri";
import Tehnicieni from "./Tehnicieni";
import Select from "react-select";
import { getZoneService, deleteZonaService } from "../backend/LocationService";
import ConfirmActionComponent from "../components/basic/ConfirmActionComponent";
import { RefreshContext } from "../context/RefreshContext";

const Setari = () => {
  const [tehnicieni, setTehnicieni] = useState([]);
  const [dispeceri, setDispeceri] = useState([]);
  const [zone, setZone] = useState([]);
  const [setari, setSetari] = useState();
  const [selectedZona, setSelectedZona] = useState(null);
  const [deleteButtonPressed, setDeleteButtonPressed] = useState(false);
  const [applications, setApplications] = useState([{value: "Logics", label: "LG"}, {value: "PixiaContur", label: "PC"}, {value: "TehnicStudio", label: "TS"}]);
  const [selectedApplication, setSelectedApplication] = useState();
  const { currentApplication, setApplication } = useContext(RefreshContext);
  const debounceRef = useRef(null);

  const getZone = async () => {
    try {
      const result = await getZoneService();
      setZone(
        result.map(zona => {
          return {
            value: zona.id,
            label: zona.nume
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const deleteZona = async () => {
    try {
      const result = await deleteZonaService(selectedZona.value);
      setSelectedZona(null);
      setZone(
        result.map(zona => {
          return {
            value: zona.id,
            label: zona.nume
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getUsers = async () => {
    try {
      const result = await getUsersService();

      setDispeceri(
        result.filter(user => user.roles.find(role => role.name === "dispecer"))
      );
      setTehnicieni(
        result.filter(user =>
          user.roles.find(role => role.name === "tehnician")
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getSetari = async () => {
    try {
      const result = await getSetariService();
      setSetari(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getSetari();
    getZone();
    getUsers();
    setSelectedApplication(applications.find(element => element.value === currentApplication));
  }, []);

  const editSetari = async () => {
    try {
      await updateSetariService(setari.id, setari);
    } catch (err) {
      console.error(err);
    }
  };

  const chooseApplication = async(application) => {
    setSelectedApplication(application);
    setApplication(application.value);
  }


  return (
    <Row>
      <Col md={12}>
        <Row className={"mb-3"}>
          <div className="headerWrapper">
            <div className="sediuMasini_header">
              <h2>Setari</h2>
            </div>
          </div>
        </Row>
        <Row className="middleScreen">
          <Col>
            <Row className="mb-2">
              <Col xs={1}>Lei/Interventie</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.leiInterventii}
                  onChange={e =>
                    setSetari({ ...setari, leiInterventii: e.target.value })
                  }
                />
              </Col>
              <Col xs={1}>Lei/Telefon</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.leiTelefon}
                  onChange={e =>
                    setSetari({ ...setari, leiTelefon: e.target.value })
                  }
                />
              </Col>
              <Col xs={1}>Zile concediu</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.zileConcediu}
                  onChange={e =>
                    setSetari({ ...setari, zileConcediu: e.target.value })
                  }
                />
              </Col>

              
            </Row>

            <Row className="mb-2">
              <Col xs={1}>Timer T1</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.reviziiT1}
                  onChange={e =>
                    setSetari({ ...setari, reviziiT1: e.target.value })
                  }
                />
              </Col>
              <Col xs={1}>Timer T2</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.reviziiT2}
                  onChange={e =>
                    setSetari({ ...setari, reviziiT2: e.target.value })
                  }
                />
              </Col>
              <Col xs={1}>Timer T3</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.reviziiT3}
                  onChange={e =>
                    setSetari({ ...setari, reviziiT3: e.target.value })
                  }
                />
              </Col>
             

            </Row>

            <Row>
            <Col xs={1}>Timer Revizie speciala</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.reviziiTSpecial}
                  onChange={e =>
                    setSetari({ ...setari, reviziiTSpecial: e.target.value })
                  }
                />
              </Col>

              <Col xs={1}>Termen ISCIR</Col>
              <Col xs={1}>
                <input
                  className="w-50"
                  value={setari && setari.termenIscir}
                  onChange={e => {
                    const value = e.target.value;
                    setSetari({ ...setari, termenIscir: e.target.value })
                    if (debounceRef.current) {
                      clearTimeout(debounceRef.current);
                    }
                    debounceRef.current = setTimeout(() => {
                      if (value < 10) {
                        setSetari({ ...setari, termenIscir: 10 })
                      } else if (value >= 100) {
                        setSetari({ ...setari, termenIscir: 99 })
                      } else {
                        setSetari({ ...setari, termenIscir: e.target.value })
                      }
                    }, 700);
                  }}
                />
              </Col>

              <Col>
                <ButtonComponent
                  text={"Edit"}
                  onClick={editSetari}
                  variant="warning"
                />
              </Col>
            </Row>

            <Row className="mb-2 mt-2">
              <Col xs={3}>
                <Select
                  options={zone}
                  placeholder={"Zona"}
                  onChange={value => setSelectedZona(value)}
                  value={selectedZona}
                />
              </Col>
              {selectedZona && (
                <>
                  <Col xs={3}>
                    <ButtonComponent
                      text={"Sterge"}
                      onClick={() => setDeleteButtonPressed(true)}
                      variant="danger"
                    />
                  </Col>
                  <ConfirmActionComponent
                    buttonIsPressed={deleteButtonPressed}
                    yesPressAction={deleteZona}
                    noPressAction={() => setDeleteButtonPressed(false)}
                    text={`Esti sigur ca vrei sa stergi zona ${selectedZona.label} ?`}
                  />
                </>
              )}
               <Col xs={3}>
                <Select
                  options={applications}
                  placeholder={"Application"}
                  onChange={value => chooseApplication(value)}
                  value={selectedApplication}
                />
              </Col>
            </Row>

            <Dispeceri dispeceri={dispeceri} />

            <Tehnicieni tehnicieni={tehnicieni} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Setari;
