import React, { useState, useEffect, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { deleteMedia } from "../backend/FileUploadService";

import { Carousel } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { BASE_URL, getUserName } from "../backend/RESTUtils";
import { RefreshContext } from "../context/RefreshContext";
import { convertDateToFullYear } from "../utils/Utils";
import { AiFillDelete } from "react-icons/ai"
import ModalComponent from "./basic/ModalComponent";
import ConfirmActionComponent from "./basic/ConfirmActionComponent";

const NewMediaSlider = () => {
  const location = useLocation();
  const [images, setImages] = useState([]);
  const { triggerRefreshNewMedia } = useContext(RefreshContext);
  const [documentToDelete, setDocumentToDelete] = useState();
  const [error, setError] = useState(null);

  const deleteDocument = async () => {
    try {
      await deleteMedia(location?.state?.locatieType,
        location?.state?.locatieFolderName,
        location?.state?.lucrareFolderName, documentToDelete);

      const updatedImageURLs = images.filter(image => image.nameAndDate.fileName !== documentToDelete);
      setImages(updatedImageURLs);

      setDocumentToDelete(null);
    } catch (err) {
      setDocumentToDelete(null);
      setError("Eroare la stergerea media.");
    }
  }

  const getFilesName = async () => {
    try {
      const domain = location?.state?.lucrareFolder.locatie.domeniu.name;
      const locationFolderName = location?.state?.lucrareFolder.locatie.originalName;
      const lucrareFolderName = location?.state?.lucrareFolder.originalName;
      const newMediaFileNames = location?.state?.lucrareFolder.media.filter(media => media.seen == false).map(item => item.name);
      const dailyMediaFileNames = location?.state?.lucrareFolder.media.map(item => item.name);
      let files = [];
      //if there is any new media, show the new media
      if (newMediaFileNames.length > 0) {
        files = newMediaFileNames;
      }
      //else show only the today media
      else {
        files = dailyMediaFileNames;
      }

      const imageURLs = [];
      for (const file of files) {
        const urls = `${BASE_URL}/Logics/files/showMedia?domain=${domain}&locationFolderName=${locationFolderName}&lucrareFolderName=${lucrareFolderName}&user=${getUserName()}&document=${file}`;
        const nameAndDate = parseFileName(file);
        imageURLs.push({ urls, nameAndDate });
      }

      setImages(imageURLs);
      triggerRefreshNewMedia();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFilesName();
  }, []);


  const parseFileName = (fileName) => {
    const parts = fileName.split("_");
    return {
      date: parts[0] || "",
      username: parts[1]?.toUpperCase() || "",
      descriere: parts[3]?.split(".")[0] || "",
      fileName,
    };
  };
  const isImage = (document) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;
    const result = imageExtensions.test(document);
    return result;
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  return (
    <Row>
      <Col md={9}>
        <>
          <Row className={"mb-3"}>
            <div className="headerWrapper" style={{ display: "flex", flexDirection: "row" }}>
              <div className="media_header" style={{ textAlign: "left", paddingLeft: 10 }}>
                <h2 style={{ fontSize: 30, color: "#0273b7" }}>{"Imagini din:"}</h2>
                <h2 style={{ fontSize: 25 }}>{truncateText(`${location?.state?.lucrareFolder?.name}`, 70)}</h2>
              </div>
            </div>
          </Row>
          <>{error && (
            <ModalComponent
              size={error ? "sm" : "md"}
              show={error !== null}
              close={() => setError()}
              body={<h3 style={{ color: "red" }}>{error}</h3>}
            />
          )}</>
          <Row className="middleScreen">
            {documentToDelete != null && <ConfirmActionComponent
              buttonIsPressed={true}
              showButtons={false}
              yesPressAction={deleteDocument}
              noPressAction={() => setDocumentToDelete(null)}
              text={`Esti sigur ca vrei sa stergi media?`}
            />}
            <Col>
              <div className="box">
                <Carousel
                  className="carousel"
                  infiniteLoop
                  controls={false} // pentru a elimina butoanele de control
                  indicators={false} // pentru a elimina indicatorii de slide
                  interval={null} // pentru a opri automatizarea carouselului
                >
                  {images.map((imageURL, index) =>
                    <div className="body_img" key={index}>
                      {isImage(imageURL.urls) ? (
                        <img
                          className="d-block w-100"
                          src={imageURL.urls}
                          alt={`${index}`}
                          key={index}
                        />) :
                        (<video controls="controls" autoPlay="" className="w-100">
                          <source
                            src={imageURL.urls}
                            type="video/mp4"
                            key={index}
                          />
                        </video>)
                      }
                      <div style={{ display: "flex", flexDirection: "column", marginBottom: 20, borderBottom: 10, border: "1px solid black", backgroundColor: "black" }}>
                        <p style={{ textAlign: "center", fontSize: 25, margin: 0, color: "white" }}>
                          {imageURL.nameAndDate.username}{" "}
                          {convertDateToFullYear(imageURL.nameAndDate.date)}
                        </p>
                        <p style={{ textAlign: "center", fontSize: 25, margin: 0, color: "white" }}>
                          {imageURL.nameAndDate.descriere}
                        </p>
                        <div style={{ backgroundColor: "black", textAlign: "center" }}>
                          <AiFillDelete onClick={() => setDocumentToDelete(imageURL?.nameAndDate?.fileName)} size={32} color="red" style={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    </div>

                  )}
                </Carousel>
              </div>
            </Col>
          </Row>
        </>
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        {/* locationType, name, id sunt necesare pt butonul de back */}
        <TabsLucrare
          locationType={location?.state?.lucrareFolder.locatie.domeniu.name}
          locatieName={location?.state?.lucrareFolder.locatie.name}
          locatieId={location?.state?.lucrareFolder.locatie.id}
        />
      </Col>
    </Row>
  );
};
export default NewMediaSlider;
