import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import "../style/Lucrare.css";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
    activareLucrareProgramataService,
    addLucrareProgramataImportantaService,
    addLucrareProgramataService
} from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";
import {
    showDateFormatFullYear,
} from "../utils/Utils";
import DatePicker from "react-datepicker";
import TextAreaComponent from "../components/basic/TextAreaComponent";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';
import "react-datepicker/dist/react-datepicker.css";
const ProgramateModal = ({
    refreshLucrari,
    closeModal,
    programataImportanta = false,
    currentLucrare
}) => {
    const [isRed, setIsRed] = useState(false);
    const [error, setError] = useState("");
    const [errorValidare, setErrorValidare] = useState("");
    const { blocking, setBlocking } = useContext(RefreshContext);
    const [lucrare, setLucrare] = useState();

    useEffect(() => {
        if (currentLucrare != null) {
            setLucrare({
                ...lucrare,
                descriere: currentLucrare.descriere,
                uid: currentLucrare.uid,
                faza: currentLucrare.faza,
                repetitie: currentLucrare.repetitie,
                scadenta: currentLucrare.scadenta,
                locatie: currentLucrare.locatieDTO
                    ? {
                        value: currentLucrare.locatieDTO.id,
                        label: currentLucrare.locatieDTO.locatie,
                        temporar: currentLucrare.locatieDTO.temporar,
                    }
                    : null,
            });
        }
        else {
            setLucrare({ ...lucrare, scadenta: null, lucrareType: "administrativ", programata: true });
        }
    }, []);

    useEffect(() => {
        registerLocale('ro', ro);
    });

    const getBackgroundColorLocatie = (type) => {
        switch (type) {
            case "interventii":
                return "#b0b0b0";
            case "ascensoare":
                return "#9ec1e0";
            case "interfoane":
                return "#a6d8b9";
            case "psi":
                return "#f1b2ac";
            case "altele":
                return "#ffcc66";
            case "sediu":
                return "#ffe7b3";
            case "diverse":
                return "#ffe7b3";
            default:
                return "#fffff5";
        }
    };

    const customStyles = {
        singleValue: (provided, state) => {
            const color =
                isNaN(lucrare.locatie.value) || lucrare.locatie.temporar || isRed
                    ? "#c60800"
                    : "black";

            return { color };
        },
        option: (provided, state) => {
            const color = state.data.temporar ? "#c60800" : "black";
            return {
                color,
                backgroundColor: getBackgroundColorLocatie(state.data.type),
                fontSize: 18,
                paddingBottom: "5px",
                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

                active: { backgroundColor: "green" },
            };
        },
        menu: (provided, state) => {
            const display = state.options.length === 0 ? "none" : "block";
            return {
                display,
                boxSizing: "border-box",
                position: "absolute",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "10",
            };
        },
    };

    const adauga = async () => {
        try {
            setBlocking(true);
            if (!lucrare.descriere) {
                setErrorValidare("descriere");
            } else if (!lucrare.scadenta) {
                setErrorValidare("scadenta")
            } else {
                await activareLucrareProgramataService(lucrare, lucrare.uid);
                closeModal();
                refreshLucrari();
            }

        } catch (err) {
            console.error(err);
            setError(err?.response?.data?.message);
        } finally {
            setBlocking(false);
        }
    };

    const activare = async () => {
        try {
            setBlocking(true);
            if (!lucrare.descriere) {
                setErrorValidare("descriere");
            } else if (!lucrare.scadenta) {
                setErrorValidare("scadenta")
            } else {
                if (programataImportanta) {
                    await addLucrareProgramataImportantaService(lucrare);
                } else {
                    await addLucrareProgramataService(lucrare);
                }
                closeModal();
                refreshLucrari();
            }

        } catch (err) {
            console.error(err);
            setError(err?.response?.data?.message);
        } finally {
            setBlocking(false);
        }
    };

    return (
        <>
            <>{error && (<h3 style={{ color: `#c60800` }}>{error}</h3>)}</>
            <>{!error && (<div><Row>
                <Col className="text-center mt-3">
                    <div className="text-center">
                        <TextAreaComponent
                            className={errorValidare === "descriere" ? "descriereError" : ""}
                            placeholder="Descriere"
                            rows={3}
                            cols={50}
                            value={lucrare ? lucrare.descriere : ""}
                            onChange={(e) => {
                                setLucrare({ ...lucrare, descriere: e.target.value });
                                setError("");
                            }}
                            autoFocus
                        >
                        </TextAreaComponent>
                    </div>
                </Col>
            </Row>
                <Row>
                    <Col className="text-center mt-3">
                        <DatePicker
                            locale="ro"
                            //className="date_picker_input"
                            className={errorValidare === "scadenta" ? "scadentaError" : "date_picker_input"}
                            dateFormat="dd/MM/yyyy"
                            selected={
                                lucrare && lucrare.scadenta
                                    ? lucrare.scadenta
                                    : ""
                            }
                            value={
                                lucrare && lucrare.scadenta
                                    ? `${showDateFormatFullYear(
                                        lucrare.scadenta
                                    )}`
                                    : "Data"
                            }
                            minDate={new Date()}
                            onChange={(date) => {
                                console.log("date", date);
                                setLucrare({ ...lucrare, scadenta: date });
                                setErrorValidare("")
                            }}
                        />
                    </Col>
                </Row>
                {(programataImportanta === true &&
                    <Row>
                        <Col className="text-center mt-3">
                            <div className="factura_wrapper">
                                <div className="mr-2">Repetare</div>
                                <input
                                    type="checkbox"
                                    style={{
                                        width: "22px",
                                        height: "22px",
                                    }}
                                    checked={lucrare && lucrare.repetitie}
                                    onChange={e => {
                                        setLucrare({ ...lucrare, repetitie: !lucrare.repetitie });
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>)}
                <Row>
                    <Col className="text-center mt-3">
                        {lucrare?.faza === 1 && <ButtonComponent
                            variant="success"
                            text="Activare"
                            onClick={adauga}
                            disabled={blocking}
                        />}
                        {lucrare?.faza == null &&
                            <ButtonComponent
                                variant="success"
                                text="Adauga"
                                onClick={activare}
                                disabled={blocking}
                            />}
                    </Col>
                </Row></div>)
            }</>
        </>
    );
};

export default ProgramateModal;
