import React, { useState, useEffect, useContext } from "react";
import Locatie from "./Locatie";
import Detalii from "./Detalii";
import ObservatiiLucrare from "./ObservatiiLucrare";
import "../style/Tabs.css";
import ModalComponent from "../components/basic/ModalComponent";
import ObservatieLucrare from "../lucrare/observatie/ObservatieLucrare";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";

const TabsLucrare = ({
  activeTab,
  locationType,
  currentLucrare,
  setCurrentLucrare,
  refreshLucrari,
  setTab,
  locatieName,
  locatieId,
}) => {
  const [index, setIndex] = useState(0);
  const { isAdmin, isDispecer } = useContext(UserContext);
  const [modalObservatie, setModalObservatie] = useState(false);
  const history = useHistory();

  const closeModalObservatie = () => {
    setModalObservatie(false);
  };

  const rightClickObsTab = (e) => {
    e.preventDefault();
    if (currentLucrare) setModalObservatie(true);
  };

  const rightClickLocatieTab = (e) => {
    e.preventDefault();

    if (isAdmin() || isDispecer()) {
      if (locationType)
        history.push("/locatii", {
          locationType,
          locatieId:
            currentLucrare &&
            currentLucrare.locatieDTO &&
            currentLucrare.locatieDTO.id,
        });
    }
  };

  const getCurrentTab = (tab) => {
    switch (tab) {
      case "locatie":
        return 0;
      case "detalii":
        return 1;
      case "observatii":
        return 2;
      default:
        return 0;
    }
  };

  const getCurrentScreen = () => {
    switch (index) {
      case 0:
        return (
          <Locatie
            currentLucrare={currentLucrare}
            locationType={locationType}
            locatieName={locatieName}
            locatieId={locatieId}
          />
        );
      case 1:
        return (
          <Detalii
            lucrareId={currentLucrare && currentLucrare.uid}
            refreshLucrari={refreshLucrari}
            setCurrentLucrare={setCurrentLucrare}
          />
        );
      case 2:
        return (
          <ObservatiiLucrare
            lucrareId={currentLucrare && currentLucrare.uid}
            refreshLucrari={refreshLucrari}
          />
        );
      default:
        return (
          <Locatie
            currentLucrare={currentLucrare}
            locationType={locationType}
            locatieName={locatieName}
            locatieId={locatieId}
          />
        );
    }
  };
  useEffect(() => {
    setIndex(getCurrentTab(activeTab));
  }, [activeTab, currentLucrare]);

  return (
    <>
      <div className="tabs_wrapper">
        <div
          onClick={() => setIndex(0)}
          onContextMenu={(e) => rightClickLocatieTab(e)}
          className={index === 0 ? "tab_active" : "tab_not_active"}
        >
          <p
            className={index === 0 ? "tab_text_active" : "tab_text_not_active"}
          >
            {locationType === "comenzi" ? "Furnizor" : "Locatie"}
          </p>
        </div>
        <div
          onClick={() => setIndex(1)}
          className={index === 1 ? "tab_active" : "tab_not_active"}
        >
          <p
            className={index === 1 ? "tab_text_active" : "tab_text_not_active"}
          >
            Detalii
          </p>
        </div>
        <div
          onClick={() => setIndex(2)}
          onContextMenu={(e) => rightClickObsTab(e)}
          className={index === 2 ? "tab_active" : "tab_not_active"}
        >
          <p
            className={index === 2 ? "tab_text_active" : "tab_text_not_active"}
          >
            Observatii
          </p>
        </div>
      </div>
      {getCurrentScreen()}
      <ModalComponent
        size={"md"}
        show={modalObservatie}
        close={closeModalObservatie}
        headingText={"Observatie"}
        body={
          <ObservatieLucrare
            lucrareId={currentLucrare && currentLucrare.uid}
            closeModalObservatie={closeModalObservatie}
            setTab={setTab}
          />
        }
      />
    </>
  );
};

export default TabsLucrare;
