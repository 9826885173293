import React, { useState, useEffect, useContext } from "react";
import PontajItem from "./PontajItem";
import {
  getTehnicianPontajeService,
  getUserRezumatPontajeService,
} from "../backend/PontajeService";
import { getCurrentMonthWithThreeCharacters } from "../utils/Utils";
import { UserContext } from "../context/UserContext";

const PontajTehnician = ({ user }) => {
  const [pontaje, setPontaje] = useState([]);
  const [triggerPontaje, setTriggerPontaje] = useState(false);
  const [pontajeRezumat, setPontajeRezumat] = useState();
  const [pageSize, setPageSize] = useState(30);
  const [pageNumber, setPageNumber] = useState(1);
  const { isAdmin } = useContext(UserContext);

  const getPontajeUser = async () => {
    try {
      const result = await getTehnicianPontajeService(
        user,
        pageSize,
        pageNumber
      );
      if (pontaje.length > 0 && pontaje[0].user !== user) {
        setPontaje(result);
      } else {
        setPontaje(pontaje.concat(result));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getRezumatPontaje = async () => {
    try {
      const result = await getUserRezumatPontajeService(user);
      setPontajeRezumat(result);
    } catch (err) {
      console.error(err);
    }
  };

  const refreshPontaje = () => {
    setTriggerPontaje(!triggerPontaje);
  };

  useEffect(() => {
    if (user) {
      getPontajeUser();
      getRezumatPontaje();
    }
  }, [triggerPontaje, user]);

  useEffect(() => {
    if (user) getPontajeUser();
  }, [pageNumber, pageSize]);

  return (
    <div>
      <div className="pontaj_rezumat_first_line_wrapper">
        <div>
          CO:{" "}
          {pontajeRezumat &&
            pontajeRezumat.concediu !== 0 &&
            pontajeRezumat.concediu}
        </div>
        <div>
          ZL:{" "}
          {pontajeRezumat &&
            pontajeRezumat.zileLibere !== 0 &&
            pontajeRezumat.zileLibere}
        </div>
        <div>
          CM:{" "}
          {pontajeRezumat &&
            pontajeRezumat.medical !== 0 &&
            pontajeRezumat.medical}
        </div>
        <div>
          AbN:{" "}
          <span>
            {pontajeRezumat &&
              pontajeRezumat.absNemotivate !== 0 &&
              pontajeRezumat.absNemotivate}
          </span>
        </div>
      </div>
      <div className="pontaj_rezumat_second_line_wrapper">
        <div>
          OL: {" "} {pontajeRezumat && pontajeRezumat.oreLucrate}[
          {pontajeRezumat && pontajeRezumat.oreLucrateParanteze}]
        </div>
        <div>
          Inter: {" "}
          {pontajeRezumat && pontajeRezumat.interventii}
        </div>
        <div>Tel: {pontajeRezumat && pontajeRezumat.telefoane}</div>
        <div>
          OS: {pontajeRezumat && pontajeRezumat.oreSuplimentare}[
          {pontajeRezumat && pontajeRezumat.oreSuplimentareParanteze}]
        </div>
      </div>
      <div className="pontaj_rezumat_3rd_line_wrapper">
        <div>
          Avans: {" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            pontajeRezumat && pontajeRezumat.avans
              ? pontajeRezumat && pontajeRezumat.avans
              : 0
          )}
        </div>
        <div>
          Plata: {" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            pontajeRezumat && pontajeRezumat.plata
              ? pontajeRezumat && pontajeRezumat.plata
              : 0
          )}
        </div>

        <div>
          Total {getCurrentMonthWithThreeCharacters()}:{" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            pontajeRezumat && pontajeRezumat.total
              ? pontajeRezumat && pontajeRezumat.total
              : 0
          )}
        </div>
        
      </div>
      {isAdmin() && <div className="pontaj_rezumat_3rd_line_wrapper">
        <div>
          Estimare plata luna curenta: {" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            pontajeRezumat && pontajeRezumat.plataEstimareCurrentMonth
              ? pontajeRezumat && pontajeRezumat.plataEstimareCurrentMonth
              : 0
          )}
        </div>
      </div>}
      <div
        onScroll={(e) => {
          const bottom =
            e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight;
          if (bottom) {
            setPageNumber(pageNumber + 1);
          }
        }}
        className="tab_pontaje_tehnician"
      >
        {pontaje.map((pontaj, index) => (
          <PontajItem
            key={index}
            pontaj={pontaj}
            refreshPontaje={refreshPontaje}
          />
        ))}
      </div>
    </div>
  );
};

export default PontajTehnician;
