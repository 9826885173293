import { doGet, doPostWithFormDataWithTimeout } from "./RESTUtils";

export const getFoldersForLocation = async (
  locationType,
  locationName,
  locationId
) => {
  return await doGet(
    `/Logics/files/getFoldersForLocation?domain=${locationType}&locationName=${locationName}&locationId=${locationId}`
  );
};

export const getMediaFilesName = async (
  locationType,
  locationFolderName,
  lucrareFolderName,
  existingFiles
) => {
  return await doGet(
    `/Logics/files/getMediaFilesName?domain=${locationType}&locationFolderName=${locationFolderName}&lucrareFolderName=${lucrareFolderName}`,
    existingFiles,
  );
};

export const getNewMedia = async () => {
  return await doGet(`/Logics/files/getNewMedia`);
}

export const uploadDocument = async (formData, locationType, locationFolderName) => {
  return await doPostWithFormDataWithTimeout(`/Logics/files/document?locationFolderName=${locationFolderName}&domain=${locationType}`, formData);
};

export const getDocumentFileNames = async (
  locationType,
  locationFolderName
) => {
  return await doGet(`/Logics/files/getDocumentFileNames?domain=${locationType}&locationFolderName=${locationFolderName}`);
};

export const downloadLocatieDocument = async (
  locationType,
  locationFolderName,
  documentName
) => {
  return await doGet(`/Logics/files/downloadDocument?domain=${locationType}&locationFolderName=${locationFolderName}&document=${documentName}`, { responseType: 'arraybuffer' });
};

export const deleteLocatieDocument = async (
  locationType,
  locationFolderName,
  documentName
)=> {
  return await doGet(`/Logics/files/deleteLocatieDocument?domain=${locationType}&locationFolderName=${locationFolderName}&documentName=${documentName}`);
};

export const deleteMedia = async (locationType, locationFolderName, lucrareFolderName, documentName) => {
  return await doGet(`/Logics/files/deleteDocument?domain=${locationType}&locationFolderName=${locationFolderName}&lucrareFolderName=${lucrareFolderName}&documentName=${documentName}`);
}

export const updateLucrareFolderName = async (locationType, locationFolderName, oldLucrareFolderName, newLucrareFolderName) => {
  return await doGet(`/Logics/files/updateLucrareFolderName?domain=${locationType}&locationFolderName=${locationFolderName}&oldLucrareFolderName=${oldLucrareFolderName}&newLucrareFolderName=${newLucrareFolderName}`);
}
