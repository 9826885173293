export function generateRandomFourDigits() {
  let randomNumber;
  do {
    randomNumber = Math.floor(Math.random() * 9000) + 1000; // Generates a number between 1000 and 9999
  } while (!isValid(randomNumber));

  return String(randomNumber);
}

function isValid(number) {
  // Check if the number is one of the excluded values
  const excludedValues = [0, 1111, 2222, 3333, 4444, 5555, 6666, 7777, 8888, 9999, 1234];
  return !excludedValues.includes(number);
}
