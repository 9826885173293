import React, { useState, useContext, useEffect } from "react";
import ListUseri from "../components/ListUseri";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
  addDeDiscutatService,
  getDiscutieService,
  updateDiscutieService
} from "../backend/LucrareService";
import { getUserName } from "../backend/RESTUtils";
import TextAreaComponent from "../components/basic/TextAreaComponent";
import { Row, Col } from "react-bootstrap";
import { RefreshContext } from "../context/RefreshContext";
import { listTehnicieni, listDispeceri } from "../utils/Values";

const DeDiscutat = ({
  closeModalDeDiscutat,
  closeModalDeDiscutatReminder,
  lucrareId,
  refreshLucrari,
  discutieId
}) => {
  const [useriDispeceri, setUseriDispeceri] = useState([]);
  const [useriTehnicieni, setUseriTehnicieni] = useState([]);
  const [descriere, setDescriere] = useState("");
  const { refreshNumere, blocking, setBlocking } = useContext(RefreshContext);

  const addOrupdateDeDiscutat = async () => {
    try {
      setBlocking(true)
      if (discutieId) {
        await updateDiscutieService(
          {
            //remove duplicate items
            useriParticipanti: [...useriDispeceri, ...useriTehnicieni],
            descriere
          },
          discutieId
        );
      } else {
        await addDeDiscutatService(
          {
            useriParticipanti: [...useriDispeceri, ...useriTehnicieni],
            descriere
          },
          lucrareId
        );
      }
      refreshNumere();
      refreshLucrari();
      closeModalDeDiscutat();
      closeModalDeDiscutatReminder();
    } catch (err) {
      console.error(err);
    }
    finally {
      setBlocking(false)
    }
  };

  const clickUserDispecerItem = user => {
    if (useriDispeceri.includes(user.toLowerCase())) {
      setUseriDispeceri(
        useriDispeceri.filter(usr => usr.toLowerCase() !== user.toLowerCase())
      );
    } else {
      setUseriDispeceri([...useriDispeceri, user.toLowerCase()]);
    }
  };

  const classUserDispecerItem = user => {
    return useriDispeceri.includes(user.toLowerCase())
      ? "pontaj_header_user_item_selected"
      : "pontaj_header_user_item";
  };

  const clickUserTehnicianItem = user => {
    if (useriTehnicieni.includes(user.toLowerCase())) {
      setUseriTehnicieni(
        useriTehnicieni.filter(usr => usr.toLowerCase() !== user.toLowerCase())
      );
    } else {
      setUseriTehnicieni([...useriTehnicieni, user.toLowerCase()]);
    }
  };

  const classUserTehnicianItem = user => {
    return useriTehnicieni.includes(user.toLowerCase())
      ? "pontaj_header_user_item_selected"
      : "pontaj_header_user_item";
  };

  const getDiscutie = async () => {
    try {
      const result = await getDiscutieService(discutieId);
      setDescriere(result.descriere);
      let dispeceri = [];
      let tehnicieni = [];
      result.useriParticipanti.forEach(user => {
        if (listDispeceri.includes(user.toUpperCase())) dispeceri.push(user);
        else tehnicieni.push(user);
      });
      setUseriTehnicieni(tehnicieni);
      setUseriDispeceri(dispeceri);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (discutieId) getDiscutie();
  }, [discutieId]);

  const checkedDispeceri = () => {
    return (
      useriDispeceri.includes("hv") &&
      useriDispeceri.includes("zc") &&
      useriDispeceri.includes("fg") &&
      useriDispeceri.includes("abd")
    );
  };
  return (
    <Row>
      <Col className="text-center">
        <div className="mb-3 de_discutat_useri_wrapper">
          <input
            className="de_discutat_checkbox"
            type="checkbox"
            checked={checkedDispeceri()}
            onChange={() => {
              if (useriDispeceri.length > 0) setUseriDispeceri([]);
              else setUseriDispeceri(["fg", "abd", "zc", "hv"]);
            }}
          />
          <div className="de_discutat_list_useri_wrapper">
            <ListUseri
              listUsers={listDispeceri.filter(
                user => user !== getUserName().toLocaleUpperCase()
              )}
              className={"lucrare_useri_wrapper"}
              onClickUser={user => clickUserDispecerItem(user)}
              classUser={user => classUserDispecerItem(user)}
            />
          </div>
        </div>
        <div className="mb-3 de_discutat_useri_wrapper">
          <input
            className="de_discutat_checkbox"
            type="checkbox"
            checked={useriTehnicieni.length === 5}
            onChange={() => {
              if (useriTehnicieni.length > 0) setUseriTehnicieni([]);
              else setUseriTehnicieni(["ab", "aa", "ns", "st"]);
            }}
          />
          <div className="de_discutat_list_useri_wrapper">
            <ListUseri
              listUsers={listTehnicieni.filter(
                user => user !== getUserName().toLocaleUpperCase()
              )}
              className={"lucrare_useri_wrapper"}
              onClickUser={user => clickUserTehnicianItem(user)}
              classUser={user => classUserTehnicianItem(user)}
            />
          </div>
        </div>
        <div className="text-center">
          <TextAreaComponent
            placeholder="De Discutat"
            rows={3}
            cols={50}
            value={descriere}
            onChange={e => {
              setDescriere(e.target.value);
            }}
            autoFocus
          />
        </div>
        <div className="text-center">
          <ButtonComponent
            text={discutieId ? "Modifica" : "Adauga"}
            onClick={addOrupdateDeDiscutat}
            variant={discutieId ? "warning" : "primary"}
            disabled={blocking}
          />
        </div>
      </Col>
    </Row>
  );
};

export default DeDiscutat;
