import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { updateLucrareDatesService } from "../backend/LucrareService";
import ButtonComponent from "../components/basic/ButtonComponent";
import { showDateFormatFullYear, showTimeFormat } from "../utils/Utils";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';

const DataLucrare = ({
  lucrare,
  setLucrare,
  closeModal,
  closeModalLucrare,
  refreshLucrari,
}) => {
  const [createDate, setCreateDate] = useState(lucrare?.createDate);
  const [realizareDate, setRealizareDate] = useState(lucrare?.realizatDate);

  useEffect(() => {
    setCreateDate(lucrare?.createDate);
    setRealizareDate(lucrare?.realizatDate);
  }, [lucrare]);

  
  useEffect(() => {
    registerLocale('ro', ro);
  });

  const updateLucrareDates = async () => {
    try {
      const result = await updateLucrareDatesService(
        lucrare?.uid,
        createDate,
        realizareDate
      );
      setLucrare({
        ...lucrare,
        createDate: result?.createDate,
        realizatDate: result?.realizatDate,
      });
      refreshLucrari();
      closeModal();
      closeModalLucrare();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="locatieInputWrapper">
            <p>Data Incepere</p>
            <DatePicker
              locale="ro"
              showTimeSelect
              timeFormat="HH:mm"
              className="input_locatie"
              dateFormat="dd/MM/yyyy"
              selected={createDate ? new Date(createDate) : new Date()}
              value={
                createDate
                  ? `${showDateFormatFullYear(createDate)} ${showTimeFormat(
                      new Date(createDate)
                    )}`
                  : "Data/Ora"
              }
              onChange={(date) => {
                setCreateDate(date);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <div className="locatieInputWrapper">
            <p>Data Realizare</p>
            <DatePicker
              locale="ro"
              showTimeSelect
              timeFormat="HH:mm"
              className="input_locatie"
              dateFormat="dd/MM/yyyy"
              selected={realizareDate ? new Date(realizareDate) : new Date()}
              value={
                realizareDate
                  ? `${showDateFormatFullYear(realizareDate)} ${showTimeFormat(
                      new Date(realizareDate)
                    )}`
                  : "Data/Ora"
              }
              onChange={(date) => {
                setRealizareDate(date);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="text-center">
          <ButtonComponent
            text={"Modifica"}
            onClick={updateLucrareDates}
            variant={"warning"}
          />
        </Col>
      </Row>
    </>
  );
};

export default DataLucrare;
