import React, { useState, useEffect, useContext } from "react";
import { getObservatiiLucrareService } from "../backend/ObservatieService";
import ObservatieLucrareItem from "../lucrare/observatie/ObservatieLucrareItem";
import { RefreshContext } from "../context/RefreshContext";

const ObservatiiLucrare = ({ lucrareId, refreshLucrari }) => {
  const { triggerObservatiiLucrare } = useContext(RefreshContext);
  const [observatii, setObservatii] = useState([]);

  const getObservatii = async () => {
    try {
      const result = await getObservatiiLucrareService(lucrareId);
      setObservatii(result);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (lucrareId) getObservatii();
  }, [triggerObservatiiLucrare]);

  return (
    <div className="mt-2 observatii_wrapper">
      {observatii.length > 0 ? (
        <>
          {observatii.map((observatie, index) => (
            <ObservatieLucrareItem
              key={index}
              lucrareId={lucrareId}
              observatie={observatie}
              refreshLucrari={refreshLucrari}
            />
          ))}
        </>
      ) : (
        <div className="noObservatii">Nu exista observatii</div>
      )}
    </div>
  );
};

export default ObservatiiLucrare;
