// lucrarile (folders) ce au poze
import React from "react";
import { Row, Col } from "react-bootstrap";
import "../style/LucrareItem.css";
import { useHistory } from "react-router-dom";
import { convertDateToFullYear } from "../utils/Utils";
import { useState } from "react";
import ModalComponent from "../components/basic/ModalComponent";
import ButtonComponent from "../components/basic/ButtonComponent";
import { updateLucrareFolderName as updateLucrareFolderNameApi } from "../backend/FileUploadService";

const FolderLucrare = ({
  locationType,
  locationName,
  locationId,
  locationFolderName,
  lucrare,
  currentLucrare,
  refreshAction
}) => {
  const history = useHistory();
  const { name, date, originalName } = lucrare;
  const [showPinToTopModal, setShowPinToTopModal] = useState(false);
  const [error, setError] = useState(null);

  const rightClickLucrare = ((e) => {
    e.preventDefault();
    setShowPinToTopModal(true);
  });

  const updateLucrareFolderName = async () => {
    try {
      const newLucrareFolderName = originalName.startsWith("top_") ? originalName.slice(4) : `top_${originalName}`;
      await updateLucrareFolderNameApi(locationType, locationFolderName, originalName, newLucrareFolderName);
      await refreshAction();
      setShowPinToTopModal(false);
    } catch (err) {
      setError("Eroare");
    }
  };

  return (
    <div>
      <ModalComponent
        size={"md"}
        show={showPinToTopModal}
        close={() => { setShowPinToTopModal(false); setError(null); }}
        headingText={""}
        body={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {error != null && <h3 style={{ color: "red" }}>{error}</h3>}
            {error == null && <div>
              <div style={{ fontSize: 25, marginBottom: 20 }}>{originalName.startsWith("top_") ? "Anuleaza marcarea ca important" : "Marcheaza ca important"}</div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <ButtonComponent
                  text="OK"
                  onClick={() => updateLucrareFolderName()}
                />
              </div>
            </div>}
          </div>
        }
      />
      <Row
        className={
          currentLucrare && currentLucrare.uid === lucrare.uid
            ? "selectedLucrare"
            : "mb-1"
        }
      >
        <Row
          className="row_folder"
          onClick={() => {
            history.push("/documentSlider", {
              date: date,
              locatieType: locationType,
              locatieId: locationId,
              locatieName: locationName,
              locatieFolderName: locationFolderName,
              lucrareFolderName: originalName,
              name: name,
              currentLucrare: currentLucrare
            });
          }}
          onContextMenu={(e) => rightClickLucrare(e)}
        >
          <Col className="lucrare_item_locatie" style={{ background: "#ffe7b3" }} md={4}>
            {lucrare?.date ? convertDateToFullYear(lucrare.date) : ""}
          </Col>

          <Col
            className="lucrare_folder_data"
            style={{
              background: originalName.startsWith("top_") ? "#8E44AD" : "#87cefa",
              color: originalName.startsWith("top_") ? "white" : "black"
            }}
            md={8}
          >
            {lucrare.name}
          </Col>
        </Row>
      </Row>
    </div>

  );
};
export default FolderLucrare;
