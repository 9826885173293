import firebase from "firebase/compat/app";
import {getDatabase} from "firebase/database";
const DB_CONFIG = {
  apiKey: "AIzaSyChDCkWwZT2xM9YGU5SryLIToT9zZ2kSQ8",
  authDomain: "logics-ff383.firebaseapp.com",
  databaseURL: "https://logics-ff383-default-rtdb.firebaseio.com",
  projectId: "logics-ff383",
  storageBucket: "logics-ff383.appspot.com",
  messagingSenderId: "164055251006",
  appId: "1:164055251006:web:b9a71a427892778aa4796f",
  measurementId: "G-SGSGSRFMRF"
};

const fire = firebase.initializeApp(DB_CONFIG);
const database = getDatabase(fire);

export { database };
