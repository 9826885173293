import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonComponent from "../components/basic/ButtonComponent";
import { deleteReminderService } from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";
import "react-datepicker/dist/react-datepicker.css";

const ModalReminderDelete = ({
    closeModalReminderDelete,
    currentReminder,
    refreshRemindere,
}) => {
    const [error, setError] = useState("");
    const { blocking, setBlocking } = useContext(RefreshContext);

    const realizare = async () => {
        try {
            setBlocking(true);

            await deleteReminderService(currentReminder.uid);
            closeModalReminderDelete();
            refreshRemindere();

        } catch (err) {
            console.error(err);
            setError(err?.response?.data?.message);
        } finally {
            setBlocking(false);
        }
    };

    return (
        <>
            <>{error && (<h3 style={{ color: `#c60800` }}>{error}</h3>)}</>
            <>{!error && (<div>
                <Row>
                    <Col className="text-center mt-3">
                        <ButtonComponent
                            variant="success"
                            text="OK"
                            onClick={realizare}
                            disabled={blocking}
                        />
                    </Col>
                </Row></div>)
            }</>
        </>
    );
};

export default ModalReminderDelete;
