import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { getLucrariFinalizateAdministrativ, getLucrariFinalizateService } from "../backend/LucrareService";
import LucrareItem from "../lucrare/LucrareItem";
import { useLocation } from "react-router-dom";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";

const Finalizate = () => {
  const [lucrari, setLucrari] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const location = useLocation();
  const { internet, serverError, istoricLocationId } =
    useContext(RefreshContext);
  const [pageSize, setPageSize] = useState(30);
  const [pageNumber, setPageNumber] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const setTab = (value) => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const getLucrari = async () => {
    try {
      const result = await getLucrariFinalizateService(
        location?.state?.locatieId,
        pageSize,
        pageNumber
      );
      if (lucrari.length && pageNumber === 1) {
        setLucrari(result);
      } else {
        setLucrari(lucrari.concat(result));
      }
      setDataLoaded(true);
      if (result.length > 0) {
        setCurrentLucrare(result[0]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getLucrariFinalizateAdm = async () => {
    try {
      const result = await getLucrariFinalizateAdministrativ(
        pageSize,
        pageNumber
      );
      if (lucrari.length && pageNumber === 1) {
        setLucrari(result);
      } else {
        setLucrari(lucrari.concat(result));
      }
      setDataLoaded(true);
      if (result.length > 0) {
        setCurrentLucrare(result[0]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (pageNumber != null) {
      if (istoricLocationId) {
        getLucrari();
      } else {
        getLucrariFinalizateAdm(pageSize, pageNumber);
      }
    }
  }, [triggerLucrari, pageNumber, pageSize]);

  useEffect(() => {
    if (pageNumber == 1) {
      refreshLucrari();
    }
    setPageNumber(1);
  }, [istoricLocationId]);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div className="ascensoare_header">
                  <h2>Istoric</h2>
                </div>
              </div>
            </Row>
            <Row
              className="middleScreen"
              onScroll={(e) => {
                const bottom =
                  e.target.scrollHeight - e.target.scrollTop ===
                  e.target.clientHeight;
                if (bottom) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            >
              <Col>
                {lucrari.length === 0 && dataLoaded === true && istoricLocationId != null && (
                  <div className="lucrari_not_found">
                    Nu exista lucrari in istoricul locatiei selectate
                  </div>
                )}
                {lucrari.length === 0 && dataLoaded === true && istoricLocationId == null && (
                  <div className="lucrari_not_found">
                    Nu exista lucrari in istoric
                  </div>
                )}
                {lucrari.map((lucrare) => (
                  <LucrareItem
                    key={lucrare.uid}
                    refreshLucrari={refreshLucrari}
                    lucrare={lucrare}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    setTab={setTab}
                  />
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType={
            location && location.state && location.state.locationType
          }
          currentLucrare={currentLucrare}
          setCurrentLucrare={setCurrentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
          locatieName={location && location.state && location.state.locatieName}
          locatieId={location && location.state && location.state.locatieId}
        />
      </Col>
    </Row>
  );
};

export default Finalizate;
