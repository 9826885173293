import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getMediaFilesName, deleteMedia } from "../backend/FileUploadService";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import ConfirmActionComponent from "./basic/ConfirmActionComponent";
import { AiFillDelete } from "react-icons/ai"

import { Carousel } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { BASE_URL } from "../backend/RESTUtils";
import ModalComponent from "./basic/ModalComponent";
import { convertDateToFullYear } from "../utils/Utils";
const DocumentSlider = () => {
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [documentToDelete, setDocumentToDelete] = useState();
  const [error, setError] = useState(null);


  const getFilesName = async () => {
    try {
      const files = await getMediaFilesName(
        location?.state?.locatieType,
        location?.state?.locatieFolderName,
        location?.state?.lucrareFolderName,
        []
      );

      const imageURLs = [];
      for (const file of files) {
        const urls = `${BASE_URL}/Logics/files/showMedia?domain=${location?.state?.locatieType}&locationFolderName=${location?.state?.locatieFolderName}&lucrareFolderName=${location?.state?.lucrareFolderName}&document=${file}`;
        const nameAndDate = parseFileName(file);
        imageURLs.push({ urls, nameAndDate });
      }
      setImages(imageURLs);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFilesName();
  }, []);

  const parseFileName = (fileName) => {
    const parts = fileName.split("_");

    return {
      date: parts[0] || "",
      username: parts[1]?.toUpperCase() || "",
      descriere: parts[3]?.split(".")[0] || "",
      fileName,
    };
  };
  const isImage = (document) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;
    const result = imageExtensions.test(document);
    return result;
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  const deleteDocument = async () => {
    try {
      await deleteMedia(location?.state?.locatieType,
        location?.state?.locatieFolderName,
        location?.state?.lucrareFolderName, documentToDelete);

      const updatedImageURLs = images.filter(image => image.nameAndDate.fileName !== documentToDelete);
      setImages(updatedImageURLs);

      setDocumentToDelete(null);
    } catch (err) {
      setDocumentToDelete(null);
      setError("Eroare la stergerea media.");
    }
  }

  return (
    <Row>
      <Col md={9}>
        <>
          <Row className={"mb-3"}>
            <div className="headerWrapper" style={{ display: "flex", flexDirection: "row" }}>
              <div className="media_header" style={{ textAlign: "left", paddingLeft: 10 }}>
                <h2 style={{ fontSize: 30, color: "#0273b7" }}>{"Imagini din:"}</h2>
                <h2 style={{ fontSize: 25 }}>{truncateText(`${location?.state?.name}`, 70)}</h2>
              </div>
            </div>
          </Row>
          <>{error && (
            <ModalComponent
              size={error ? "sm" : "md"}
              show={error !== null}
              close={() => setError()}
              body={<h3 style={{ color: "red" }}>{error}</h3>}
            />
          )}</>
          <Row className="middleScreen">
            {documentToDelete != null && <ConfirmActionComponent
              buttonIsPressed={true}
              showButtons={false}
              yesPressAction={deleteDocument}
              noPressAction={() => setDocumentToDelete(null)}
              text={`Esti sigur ca vrei sa stergi media?`}
            />}
            <Col>
              <div className="box">
                <Carousel
                  className="carousel"
                  infiniteLoop
                  controls={false} // pentru a elimina butoanele de control
                  indicators={false} // pentru a elimina indicatorii de slide
                  interval={null} // pentru a opri automatizarea carouselului
                >
                  {images.map((imageURL, index) =>
                    <div className="body_img" key={index}>
                      {isImage(imageURL.urls) ? (
                        <img
                          className="d-block w-100"
                          src={imageURL.urls}
                          alt={`${index}`}
                          key={index}
                        />) :
                        (<video controls="controls" autoPlay="" className="w-100">
                          <source
                            src={imageURL.urls}
                            type="video/mp4"
                            key={index}
                          />
                        </video>)
                      }
                      <div style={{ display: "flex", flexDirection: "column", marginBottom: 20, borderBottom: 10, border: "1px solid black", backgroundColor: "black" }}>
                        <p style={{ textAlign: "center", fontSize: 25, margin: 0, color: "white" }}>
                          {imageURL.nameAndDate.username}{" "}
                          {convertDateToFullYear(imageURL.nameAndDate.date)}
                        </p>
                        <p style={{ textAlign: "center", fontSize: 25, margin: 0, color: "white" }}>
                          {imageURL.nameAndDate.descriere}
                        </p>
                        <div style={{ backgroundColor: "black", textAlign: "center" }}>
                          <AiFillDelete onClick={() => setDocumentToDelete(imageURL?.nameAndDate?.fileName)} size={32} color="red" style={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    </div>

                  )}
                </Carousel>
              </div>
            </Col>
          </Row>
        </>
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        {/* locationType, name, id sunt necesare pt butonul de back */}
        <TabsLucrare
          currentLucrare={location?.state?.currentLucrare}
          locationType={
            location?.state?.locatieType
          }
          locatieName={location && location.state && location.state.locatieName}
          locatieId={location && location.state && location.state.locatieId}
        />
      </Col>
    </Row>
  );
};
export default DocumentSlider;
