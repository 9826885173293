import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import "../style/LucrareItem.css";
import ModalComponent from "../components/basic/ModalComponent";
import { showDateFormat, showTimeFormat } from "../utils/Utils";
import { getRomanianDay, showDateFormatFullYear } from "../utils/Utils";
import ModalReminderDelete from "./ModalReminderDelete";

const ReminderItem = ({
  reminder,
  refreshLucrari,
  currentLucrare,
  setCurrentLucrare,
  currentReminder,
  setCurrentReminder,
  setTab,
  setRightTabPontaj,
  //folosit pentru a distinge right click din fisa zilnica de right click din celelalte tab-uri
  fromFisaZilnica = false,
}) => {

  const [modalReminderDelete, setModalReminderDelete] = useState(false);

  const getBackgroundColorLocatie = () => {
    switch (reminder.lucrare.lucrareType) {
      case "interventii":
        return "backgroudLocatieInterventii";
      case "ascensoare":
        return "backgroudLocatieAscensoare";
      case "revizii":
        return "backgroudLocatieRevizii";
      case "iscir":
        return "backgroudLocatieIscir";
      case "interfoane":
        return "backgroudLocatieInterfoane";
      case "psi":
        return "backgroudLocatiePsi";
      case "altele":
        return "backgroudLocatieAltele";
      case "sediu":
        return "backgroudLocatieSediuMasini";
      case "diverse":
        return "backgroudLocatieDiverse";
      case "oferte":
        return "backgroudLocatieOferte";
      case "comenzi":
        return "backgroudLocatieComenzi";
      case "administrativ":
        return "backgroudLocatieAdministrativ";
      case "notificari":
        return "backgroudLocatieNotificari";
      default:
        return "backgroundLocatieDefault";
    }
  };
  const getTextColoLocatie = () => {
    if (reminder.lucrare && reminder.lucrare.locatieDTO && reminder.lucrare.locatieDTO.temporar) {
      return "lucrareItemLocatieTextColorRed";
    } else {
      return "lucrareItemLocatieTextColorNormal";
    }
  };

  const rightClickLocatie = (e) => {
    e.preventDefault();
    setModalReminderDelete(true);
  };

  const closeModalReminderDelete = () => {
    setModalReminderDelete(false);
  };

  return (
    <>
      <Row
        className={
          (currentReminder && currentReminder.uid === reminder.uid)
            ? "selectedLucrare"
            : "mb-1"
        }
      >
        <Col
          className={`lucrare_item_locatie ${getBackgroundColorLocatie()} ${getTextColoLocatie()}`}
          md={2}
          onContextMenu={(e) => rightClickLocatie(e)}
          onClick={() => {
            setTab("locatie");
          }}
        >
          <div>{reminder?.lucrare?.locationName || ""}</div>
        </Col>
        <Col
          onClick={() => {
            setCurrentLucrare(reminder.lucrare);
            setCurrentReminder(reminder);
            setTab("detalii");
          }}
          style={{ backgroundColor: "#FFFFC2" }}
          md={7}
        >
          <div className="lucrare_item_descriere_obs_wrapper">
            <div className="text-center w-100">
              <div className="descriere_discutie_text_color">
                {getRomanianDay(new Date(reminder?.data))} -{" "}
                {showDateFormatFullYear(new Date(reminder?.data))}{" "}
                {showTimeFormat(new Date(reminder?.data))}
              </div>
              <div style={{ color: "#008000" }}>
                {reminder?.observatie || ""}
              </div>
              <div>
                {reminder?.lucrare?.descriere || ""}
              </div>
            </div>
            <div className="baraRosieObservatii" style={{ marginRight: "-15px" }}></div>
          </div>
        </Col>

        <Col
          md={3}
          className="lucrare_item_data"
        >
          <div className="lucrare_item_date_wrapper">
            <div className="lucrare_item_date">
              {showDateFormat(
                new Date(reminder.lucrare.createDate)
              )}{" "}
              {showTimeFormat(
                new Date(reminder.lucrare.createDate)
              )}
            </div>
            <div className="ml-2">
              {`${reminder.lucrare.createdUser}`}
            </div>
          </div>
          <>
            {reminder.lucrare.startDate && (
              <div className="lucrare_item_date_wrapper">
                <div className="lucrare_item_date">
                  {showDateFormat(new Date(reminder.lucrare.startDate))}{" "}
                  {showTimeFormat(new Date(reminder.lucrare.startDate))}{" "}
                  {reminder.lucrare.participanti && (
                    <div className="ml-2 lucrare_item_participanti">
                      {reminder.lucrare.participanti.map((participant, index) => {
                        return (
                          <div
                            key={index}
                            className={`mr-1`}
                          >{`${participant}`}</div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>

        </Col>

        <ModalComponent
          size={"90%"}
          topDistance={50}
          show={modalReminderDelete}
          close={closeModalReminderDelete}
          headingText={"Realizare"}
          body={
            <ModalReminderDelete
              closeModalReminderDelete={closeModalReminderDelete}
              currentReminder={reminder}
              refreshRemindere={refreshLucrari}
            />
          }
        />
      </Row>
    </>
  );
};

export default ReminderItem;
