import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
  getLocationViewByTypeService,
  getLocationsViewService,
} from "../backend/LocationService";
import TextAreaComponent from "../components/basic/TextAreaComponent";
import { UserContext } from "../context/UserContext";
import { RefreshContext } from "../context/RefreshContext";
import { addLocationService } from "../backend/LocationService";
import "../style/Lucrare.css";
import {
  addLucrareService,
  addLucrareComenziService,
  getLucrareService,
  updateLucrareService,
  deleteLucrareService,
  addLucrareProgramataService,
  addLucrareProgramataImportantaService
} from "../backend/LucrareService";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ListUseri from "../components/ListUseri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  showDateFormatFullYear,
  showTimeFormat,
  isLocatieCar,
} from "../utils/Utils";
import { getUserName } from "../backend/RESTUtils";
import { listTehnicieni, listDispeceri } from "../utils/Values";
import ConfirmActionComponent from "../components/basic/ConfirmActionComponent";
import ModalComponent from "../components/basic/ModalComponent";
import MutaLucrare from "./MutaLucrare";
import DataLucrare from "./DataLucrare";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';
import { AiFillDelete } from "react-icons/ai"

const Lucrare = ({
  locationType,
  closeModal,
  refreshLucrari,
  isInterventie = false,
  lucrareId,
  setCurrentLucrare,
}) => {
  const [isRed, setIsRed] = useState(false);
  const history = useHistory();
  const [locations, setLocation] = useState([]);
  const [lucrare, setLucrare] = useState();
  const { isDispecer, isAdmin } = useContext(UserContext);
  const [error, setError] = useState("");
  const { refreshNumere, blocking, setBlocking } = useContext(RefreshContext);
  const [deleteButtonPressed, setDeleteButtonPressed] = useState(false);
  const [mutaModal, setMutaModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [domenii, setDomenii] = useState([
    {
      value: "ascensoare",
      label: "Ascensoare",
    },
    {
      value: "interfoane",
      label: "Interfoane",
    },
    {
      value: "psi",
      label: "PSI",
    },
    {
      value: "altele",
      label: "Altele",
    },
  ]);

  const [selectedDomeniu, setSelectedDomeniu] = useState(null);

  const closeDataModal = () => {
    setDataModal(false);
  };

  const closeMutaModal = () => {
    setMutaModal(false);
  };

  useEffect(() => {
    setLucrare({ ...lucrare, lucrareType: locationType });
    getLocations(locationType);
  }, []);


  const clearError = () => {
    setTimeout(() => {
      setError("");
      if (!(lucrare && lucrare.locatie && lucrare.locatie.value)) {
        setLucrare({ ...lucrare, locatie: null });
      } else {
        getLucrare();
      }
    }, 4000);
  };

  const getLocations = async (locationType) => {
    try {
      let result = [];
      switch (locationType) {
        case "administrativ": {
          result = await getLocationsViewService();
          break;
        }
        case "notificari": {
          result = await getLocationsViewService();
          break;
        }
        case "diverse": {
          result = await getLocationsViewService();
          break;
        }
        case "oferte": {
          let rrr = await getLocationsViewService();
          result = rrr.filter(
            (location) =>
              location.type === "ascensoare" ||
              location.type === "interfoane" ||
              location.type === "psi" ||
              location.type === "altele"
          );
          break;
        }
        case "ascensoare": {
          result = await getLocationViewByTypeService("ascensoare");
          break;
        }
        case "revizii": {
          result = await getLocationViewByTypeService("ascensoare");
          break;
        }
        case "iscir": {
          result = await getLocationViewByTypeService("ascensoare");
          break;
        }
        case "psi": {
          result = await getLocationViewByTypeService("psi");
          break;
        }
        case "interfoane": {
          result = await getLocationViewByTypeService("interfoane");
          break;
        }
        case "altele": {
          result = await getLocationViewByTypeService("altele");
          break;
        }
        case "sediu": {
          result = await getLocationViewByTypeService("sediu");
          break;
        }
        case "comenzi": {
          result = await getLocationViewByTypeService("comenzi");
          break;
        }
        case "smart_signage": {
          result = await getLocationViewByTypeService("smart_signage");
          break;
        }
        case "software": {
          result = await getLocationViewByTypeService("software");
          break;
        }
        case "controllers": {
          result = await getLocationViewByTypeService("controllers");
          break;
        }
        case "personalizari_textile": {
          result = await getLocationViewByTypeService("personalizari_textile");
          break;
        }
        case "personalizari_obiecte": {
          result = await getLocationViewByTypeService("personalizari_obiecte");
          break;
        }
        case "tiparituri": {
          result = await getLocationViewByTypeService("tiparituri");
          break;
        }
        default:
          result = await getLocationsViewService();
      }

      setLocation(
        locationType === "diverse"
          ? result
            .filter(
              (location) =>
                location.type !== "comenzi" && location.type !== "sediu"
            )
            .map((location) => {
              return {
                value: location.id,
                label: location.locatie,
                temporar: location.temporar,
                type: location.type,
              };
            })
          : result.map((location) => {
            return {
              value: location.id,
              label: location.locatie,
              temporar: location.temporar,
              type: location.type,
            };
          })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const isLocationInDatabase = () => {
    const filterLocations = locations.filter(
      (l) => l.label.toLowerCase() === lucrare.locatie.value.toLowerCase()
    );
    return filterLocations.length > 0;
  };

  const updateLucrare = async () => {
    try {
      setBlocking(true);
      if (lucrare.descriere) {
        if (lucrare.locatie && isNaN(lucrare.locatie.value)) {
          if (!isLocationInDatabase()) {
            const result = await addLocationService({
              locatie: lucrare.locatie.value,
              type: selectedDomeniu ? selectedDomeniu?.value : locationType,
              temporar: true,
            });
            if (
              lucrare.faza <= 4 &&
              (lucrare.atribuiti?.length > 0 || lucrare.dataTermen)
            ) {
              await updateLucrareService({ ...lucrare, faza: 4 }, result.id);
            } else {
              await updateLucrareService(lucrare, result.id);
            }
            closeModal();
            refreshLucrari();
          } else {
            setError("locatie_already_exists");
            clearError();
            return;
          }
        } else {
          if (
            lucrare.faza <= 4 &&
            (lucrare.atribuiti?.length > 0 || lucrare.dataTermen)
          ) {
            await updateLucrareService(
              { ...lucrare, faza: 4 },
              lucrare.locatie && lucrare.locatie.value
            );
          } else {
            await updateLucrareService(
              lucrare,
              lucrare.locatie && lucrare.locatie.value
            );
          }
          closeModal();
          refreshLucrari();
        }
      } else {
        setError("descriere");
      }
    } catch (err) {
      console.error(err);
    }
    finally{
      setBlocking(false);
    }
  };
  const getLucrare = async () => {
    try {
      const result = await getLucrareService(lucrareId);
      if (
        result.lucrareType !== "comenzi" &&
        result.lucrareType !== "administrativ" &&
        result.lucrareType !== "notificari" &&
        !result.locatieDTO
      ) {
        setError("locatie");
      }
      setLucrare({
        ...lucrare,
        descriere: result.descriere,
        createDate: result.createDate,
        realizatDate: result.realizatDate,
        uid: result.uid,
        faza: result.faza,
        atribuiti: result.atribuiti,
        isInterventie: result.isInterventie,
        lucrareType: result.lucrareType,
        dataTermen: result.dataTermen ? new Date(result.dataTermen) : null,
        programata: result.programata,
        programataImportanta: result.programataImportanta,
        scadenta: result.scadenta ? new Date(result.scadenta) : null,
        repetitie: result.repetitie,
        locatie: result.locatieDTO
          ? {
            value: result.locatieDTO.id,
            label: result.locatieDTO.locatie,
            temporar: result.locatieDTO.temporar,
            type: result.locatieDTO.type,
          }
          : null,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    registerLocale('ro', ro);
  });

  useEffect(() => {
    if (selectedDomeniu) getLocations(selectedDomeniu?.value);
  }, [selectedDomeniu]);

  useEffect(() => {
    if (lucrareId) getLucrare(lucrareId);
  }, [lucrareId]);

  const addLucrare = async (faza) => {
    try {
      setBlocking(true);
      if (locationType === "programate") {
        await addLucrareProgramataService(
          { ...lucrare }
        );
        refreshLucrari();
        closeModal();
      } else if (locationType === "programateImportante") {
        await addLucrareProgramataImportantaService(
          { ...lucrare }
        );
        refreshLucrari();
        closeModal();
      }
      else if (
        locationType === "comenzi" ||
        locationType === "administrativ" ||
        locationType === "notificari"
      ) {
        if (lucrare && lucrare.descriere) {
          if (lucrare.locatie && isNaN(lucrare.locatie.value)) {
            if (!isLocationInDatabase()) {
              const result = await addLocationService({
                locatie: lucrare.locatie.value,
                type: selectedDomeniu ? selectedDomeniu?.value : locationType,
                temporar: true,
              });
              if (lucrare.dataTermen || lucrare.atribuiti) {
                await addLucrareService(
                  { ...lucrare, isInterventie, faza: 4 },
                  result.id
                );
              } else {
                await addLucrareService(
                  { ...lucrare, isInterventie, faza },
                  result.id
                );
              }
            } else {
              setError("locatie_already_exists");
              clearError();
              return;
            }
          } else if (lucrare.locatie && !isNaN(lucrare.locatie.value)) {
            if (lucrare.dataTermen || lucrare.atribuiti) {
              await addLucrareService(
                { ...lucrare, isInterventie, faza: 4 },
                lucrare.locatie.value
              );
            } else {
              await addLucrareService(
                { ...lucrare, isInterventie, faza },
                lucrare.locatie.value
              );
            }
          } else {
            if (lucrare.dataTermen || lucrare.atribuiti) {
              await addLucrareComenziService({
                ...lucrare,
                isInterventie,
                faza: 4,
              });
            } else {
              await addLucrareComenziService({
                ...lucrare,
                isInterventie,
                faza,
              });
            }
          }
          if (isInterventie) {
            history.push("/interventii");
            refreshLucrari();
          } else {
            refreshLucrari();
          }
          refreshNumere();
          closeModal();
        } else {
          setError("descriere");
        }
      } else {
        if (lucrare && lucrare.locatie) {
          if (lucrare && lucrare.descriere) {
            if (isNaN(lucrare.locatie.value)) {
              if (!isLocationInDatabase()) {
                const result = await addLocationService({
                  locatie: lucrare.locatie.value,
                  type: selectedDomeniu ? selectedDomeniu?.value : locationType,
                  temporar: true,
                });
                if (lucrare.dataTermen || lucrare.atribuiti) {
                  await addLucrareService(
                    { ...lucrare, isInterventie, faza: 4 },
                    result.id
                  );
                } else {
                  await addLucrareService(
                    { ...lucrare, isInterventie, faza },
                    result.id
                  );
                }
              } else {
                setError("locatie_already_exists");
                clearError();
                return;
              }
            } else {
              if (lucrare.dataTermen || lucrare.atribuiti) {
                await addLucrareService(
                  { ...lucrare, isInterventie, faza: 4 },
                  lucrare.locatie.value
                );
              } else {
                await addLucrareService(
                  { ...lucrare, isInterventie, faza },
                  lucrare.locatie.value
                );
              }
            }
            if (isInterventie) {
              history.push("/interventii");
              refreshLucrari();
            } else {
              refreshLucrari();
            }
            refreshNumere();
            closeModal();
          } else {
            setError("descriere");
          }
        } else {
          setError("locatie");
        }
      }
    } catch (err) {
      console.error(err);
    }
    finally{
      setBlocking(false);
    }
  };

  const addInterventieRosie = async (faza) => {
    try {
      setBlocking(true);
      if (lucrare.locatie && isNaN(lucrare.locatie.value)) {
        if (!isLocationInDatabase()) {
          const result = await addLocationService({
            locatie: lucrare.locatie.value,
            type: selectedDomeniu ? selectedDomeniu?.value : locationType,
            temporar: true,
          });
          await addLucrareService(
            { ...lucrare, isInterventie, faza, descriere: "Persoana blocata" },
            result.id
          );
          if (isInterventie) {
            history.push("/interventii");
            refreshLucrari();
          } else {
            refreshLucrari();
          }
          refreshNumere();
          closeModal();
        } else {
          setError("locatie_already_exists");
          clearError();
          return;
        }
      } else if (lucrare.locatie && !isNaN(lucrare.locatie.value)) {
        await addLucrareService(
          { ...lucrare, isInterventie, faza, descriere: "Persoana blocata" },
          lucrare.locatie.value
        );
        if (isInterventie) {
          history.push("/interventii");
          refreshLucrari();
        } else {
          refreshLucrari();
        }
        refreshNumere();
        closeModal();
      } else {
        if (lucrare && lucrare.locatie) {
          await addLucrareService({
            ...lucrare,
            isInterventie,
            faza: 4,
            descriere: "Persoana blocata",
          });
          if (isInterventie) {
            history.push("/interventii");
            refreshLucrari();
          } else {
            refreshLucrari();
          }
          refreshNumere();
          closeModal();
        } else {
          setError("locatie");
        }
      }
    } catch (err) {
      console.log(err);
    }
    finally{
      setBlocking(false);
    }
  };

  const addLucrareAlimentareConbustibil = async () => {
    try {
      setBlocking(true);
      await addLucrareService(
        {
          ...lucrare,
          isInterventie,
          faza: 3,
          descriere: "Alimentare combustibil",
          tipSediu: "ALIMENTARE",
        },
        lucrare.locatie.value
      );

      refreshLucrari();

      refreshNumere();
      closeModal();
    } catch (err) {
      console.log(err);
    } finally{
      setBlocking(false);
    }
  };

  const getBackgroundColorLocatie = (type) => {
    switch (type) {
      case "interventii":
        return "#b0b0b0";
      case "ascensoare":
        return "#9ec1e0";
      case "interfoane":
        return "#a6d8b9";
      case "psi":
        return "#f1b2ac";
      case "altele":
        return "#ffcc66";
      case "sediu":
        return "#ffe7b3";
      case "diverse":
        return "#ffe7b3";
      case "oferte":
        return "#ffe7b3";
      default:
        return "#fffff5";
    }
  };

  const customStyles = {
    singleValue: (provided, state) => {
      const color =
        isNaN(lucrare.locatie.value) || lucrare.locatie.temporar || isRed
          ? "#c60800"
          : "black";

      return { color };
    },
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row"
    }),
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.type),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };

  const deleteLucrare = async () => {
    try {
      await deleteLucrareService(lucrare && lucrare.uid);
      if (setCurrentLucrare) {
        setCurrentLucrare(null);
      }
      closeModal();
      refreshLucrari();
      refreshNumere();
    } catch (err) {
      console.error(err);
    }
  };

  const clickUserItem = (user) => {
    if (lucrare && lucrare.atribuiti && lucrare.atribuiti.includes(user)) {
      setLucrare({
        ...lucrare,
        atribuiti: lucrare.atribuiti.filter((usr) => usr !== user),
      });
    } else {
      if (lucrare && lucrare.atribuiti) {
        setLucrare({
          ...lucrare,
          atribuiti: [...lucrare.atribuiti, user],
        });
      } else {
        setLucrare({
          ...lucrare,
          atribuiti: [user],
        });
      }
    }
  };

  const classUserItem = (user) => {
    return lucrare && lucrare.atribuiti && lucrare.atribuiti.includes(user)
      ? "pontaj_header_user_item_selected"
      : "pontaj_header_user_item";
  };

  const customStylesDomenii = {
    singleValue: (provided, state) => {
      const color = "black";

      return { color };
    },
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.value),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        backgroundColor: "#6c757d",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };

  const showLocatieDropDown = () => {
    if (locationType === "oferte") {
      if (selectedDomeniu || lucrare?.uid) return true;
      else return false;
    }
    if ((locationType === "programate") || (locationType === "programateImportante") || (locationType === "administrativ" && lucrare?.programata === true)) return false;
    return true;
  };

  return (
    <>
      {error === "locatie_already_exists" && (
        <div className="location_already_exists">
          <h3>Exista locatia in baza de date!</h3>
        </div>
      )}
      {deleteButtonPressed ? (
        <>
          <ConfirmActionComponent
            buttonIsPressed={deleteButtonPressed}
            yesPressAction={deleteLucrare}
            noPressAction={() => setDeleteButtonPressed(false)}
            text={`Esti sigur ca vrei sa stergi lucrarea?`}
          />
        </>
      ) : (
        <>
          {mutaModal || dataModal ? (
            <>
              <ModalComponent
                size={"md"}
                show={mutaModal}
                close={closeMutaModal}
                headingText="Muta Lucrare"
                body={
                  <MutaLucrare
                    lucrare={lucrare}
                    setLucrare={setLucrare}
                    closeModal={closeMutaModal}
                    closeModalLucrare={closeModal}
                    refreshLucrari={refreshLucrari}
                  />
                }
              />
              <ModalComponent
                size={"md"}
                show={dataModal}
                close={closeDataModal}
                headingText="Schimbare Data/Ora Adaugare, Realizare"
                body={
                  <DataLucrare
                    lucrare={lucrare}
                    setLucrare={setLucrare}
                    closeModal={closeDataModal}
                    closeModalLucrare={closeModal}
                    refreshLucrari={refreshLucrari}
                  />
                }
              />
            </>
          ) : (
            <>
              <Row className="mb-3 text-center">
                <Col>
                  {locationType === "oferte" && (
                    <div
                      className={
                        error === "locatie"
                          ? "locatieError mb-2"
                          : "lucrareLocatie mb-2"
                      }
                    >
                      <Select
                        options={domenii}
                        styles={customStylesDomenii}
                        placeholder={"Domenii"}
                        formatCreateLabel={() => null || undefined}
                        noOptionsMessage={() => null}
                        onChange={(value) => {
                          setSelectedDomeniu(value);
                          setLucrare({ ...lucrare, locatie: null });
                          setError("");
                        }}
                        value={selectedDomeniu}
                      />
                    </div>
                  )}
                  {showLocatieDropDown() && (
                    <div
                      className={
                        error === "locatie" ? "locatieError" : "lucrareLocatie"
                      }
                    >
                      <CreatableSelect
                        className={isRed ? "xxx" : "yyy"}
                        onBlur={(e) => {
                          if (!(lucrare && lucrare.locatie)) {
                            setLucrare({
                              ...lucrare,
                              locatie: {
                                value: e.target.value,
                                label: e.target.value,
                              },
                            });
                            // setLocation([
                            //   ...locations,
                            //   { value: e.target.value, label: e.target.value },
                            // ]);
                            setError("");
                          }
                        }}
                        styles={customStyles}
                        placeholder={
                          locationType === "comenzi" ? "Furnizor" : "Locatie"
                        }
                        formatCreateLabel={() => null || undefined}
                        noOptionsMessage={() => null}
                        isClearable
                        isLoading={false}
                        isDisabled={false}
                        onBlurResetsInput={false}
                        onInputChange={(value) => {
                          let list = locations.filter(
                            (location) =>
                              location.label &&
                              location.label
                                .toLowerCase()
                                .includes(value.toLowerCase())
                          );
                          if (list.length === 0) {
                            setLucrare({ ...lucrare, locatie: null });
                            setIsRed(true);
                          } else setIsRed(false);
                        }}
                        onChange={(value) => {
                          setLucrare({ ...lucrare, locatie: value });
                          setError("");
                        }}
                        onCreateOption={(value) => {
                          setLucrare({
                            ...lucrare,
                            locatie: { value, label: value },
                          });
                          setLocation([...locations, { value, label: value }]);
                          setError("");
                        }}
                        options={locations}
                        value={lucrare && lucrare.locatie}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mb-3 text-center">
                <Col>
                  <TextAreaComponent
                    className={error === "descriere" ? "descriereError" : ""}
                    placeholder="Descriere"
                    rows={3}
                    cols={50}
                    value={lucrare ? lucrare.descriere : ""}
                    onChange={(e) => {
                      setLucrare({ ...lucrare, descriere: e.target.value });
                      setError("");
                    }}
                    autoFocus
                  />
                </Col>
              </Row>
              {locationType !== "notificari" && locationType !== "programate" && locationType !== "programateImportante" && !(lucrare?.programata != null && lucrare?.programata === true && lucrare?.faza === 1) && (isAdmin() || isDispecer()) && (
                <>
                 { !(lucrare?.programata != null && lucrare?.programata === true) &&
                  <Row>
                    <Col className="mb-3">
                      {locationType === "oferte" ? (
                        <>
                          <div className="mb-3 de_discutat_useri_wrapper">
                            <input
                              className="de_discutat_checkbox"
                              type="checkbox"
                              checked={lucrare?.atribuiti?.some((r) =>
                                listDispeceri.includes(r)
                              )}
                              onChange={() => {
                                if (
                                  lucrare?.atribuiti?.some((r) =>
                                    listDispeceri.includes(r)
                                  )
                                ) {
                                  setLucrare({
                                    ...lucrare,
                                    atribuiti: lucrare?.atribuiti.filter(
                                      (r) => !listDispeceri.includes(r)
                                    ),
                                  });
                                } else {
                                  setLucrare({
                                    ...lucrare,
                                    atribuiti: lucrare?.atribuiti
                                      ? [
                                        ...lucrare?.atribuiti,
                                        ...listDispeceri,
                                      ]
                                      : [...listDispeceri],
                                  });
                                }
                              }}
                            />
                            <div className="de_discutat_list_useri_wrapper">
                              <ListUseri
                                listUsers={listDispeceri}
                                className={"lucrare_useri_wrapper"}
                                onClickUser={(user) => clickUserItem(user)}
                                classUser={(user) => classUserItem(user)}
                              />
                            </div>
                          </div>
                          <div className="mb-3 de_discutat_useri_wrapper">
                            <input
                              className="de_discutat_checkbox"
                              type="checkbox"
                              checked={lucrare?.atribuiti?.some((r) =>
                                listTehnicieni.includes(r)
                              )}
                              onChange={() => {
                                if (
                                  lucrare?.atribuiti?.some((r) =>
                                    listTehnicieni.includes(r)
                                  )
                                ) {
                                  setLucrare({
                                    ...lucrare,
                                    atribuiti: lucrare?.atribuiti.filter(
                                      (r) => !listTehnicieni.includes(r)
                                    ),
                                  });
                                } else {
                                  setLucrare({
                                    ...lucrare,
                                    atribuiti: lucrare?.atribuiti
                                      ? [
                                        ...lucrare?.atribuiti,
                                        ...listTehnicieni,
                                      ]
                                      : [...listTehnicieni],
                                  });
                                }
                              }}
                            />
                            <div className="de_discutat_list_useri_wrapper">
                              <ListUseri
                                listUsers={listTehnicieni.filter(
                                  (user) =>
                                    user !== getUserName().toLocaleUpperCase()
                                )}
                                className={"lucrare_useri_wrapper"}
                                onClickUser={(user) => clickUserItem(user)}
                                classUser={(user) => classUserItem(user)}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <ListUseri
                          listUsers={
                            locationType === "administrativ" ||
                              locationType === "comenzi"
                              ? listDispeceri
                              : listTehnicieni.filter(
                                (user) =>
                                  user !== getUserName().toLocaleUpperCase()
                              )
                          }
                          className={"lucrare_useri_wrapper"}
                          onClickUser={(user) => clickUserItem(user)}
                          classUser={(user) => classUserItem(user)}
                        />
                      )}
                    </Col>
                  </Row>
                }
                  <>
                    {(!lucrare?.programata) && (isAdmin() || isDispecer()) && (
                      <Row>
                        <Col className="mb-3 ml-5 text-center">
                          <DatePicker
                            locale="ro"
                            showTimeSelect
                            timeFormat="HH:mm"
                            className="date_picker_input"
                            dateFormat="dd/MM/yyyy"
                            selected={
                              lucrare && lucrare.dataTermen
                                ? lucrare.dataTermen
                                : ""
                            }
                            value={
                              lucrare && lucrare.dataTermen
                                ? `${showDateFormatFullYear(
                                  lucrare.dataTermen
                                )} ${showTimeFormat(
                                  new Date(lucrare.dataTermen)
                                )}`
                                : "Data/Ora"
                            }
                            minDate={new Date()}
                            onChange={(date) => {
                              setLucrare({ ...lucrare, dataTermen: date });
                            }}
                          />

                          <i
                            onClick={() =>
                              setLucrare({ ...lucrare, dataTermen: null })
                            }
                            style={{ color: "#c60800" }}
                            className="far fa-times-circle fa-lg"
                          ></i>
                        </Col>
                      </Row>
                    )}
                    {(lucrare?.programata != null && lucrare?.programata === true && lucrare?.faza ===6) && (isAdmin() || isDispecer()) && (
                      <div>
                      <Row>
                        <Col className="mb-3 text-center">
                          <DatePicker
                            locale="ro"
                            className="date_picker_input"
                            dateFormat="dd/MM/yyyy"
                            selected={
                              lucrare && lucrare.scadenta
                                ? lucrare.scadenta
                                : ""
                            }
                            value={
                              lucrare && lucrare.scadenta
                                ? `${showDateFormatFullYear(
                                  lucrare.scadenta
                                )} ${showTimeFormat(
                                  new Date(lucrare.scadenta)
                                )}`
                                : "Scadenta"
                            }
                            minDate={new Date()}
                            onChange={(date) => {
                              setLucrare({ ...lucrare, scadenta: date });
                            }}
                          />
                           <i
                            onClick={() =>
                              setLucrare({ ...lucrare, scadenta: null, repetitie: false })
                            }
                            style={{ color: "#c60800" }}
                            className="far fa-times-circle fa-lg"
                          ></i>
                        </Col>
                      </Row>
                      {lucrare && lucrare.programataImportanta === true && 
                              <Row>
                                <Col className="text-center mb-3">
                                  <div className="factura_wrapper">
                                    <div className="mr-2">Repetare</div>
                                    <input
                                      type="checkbox"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                      disabled={lucrare && lucrare.scadenta == null}
                                      checked={lucrare && lucrare.repetitie}
                                      onChange={e => {
                                        setLucrare({ ...lucrare, repetitie: !lucrare.repetitie });
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>}
                          </div>
                    )}
                    
                    
                    </>
                </>
              )}
              {!isInterventie ? (
                <>
                  {lucrareId ? (
                    <>
                      <div className="modificare_stergere_wrapper">
                        <Row className="modificare_stergere_row">
                          <Col xs={4}>
                            {((!lucrare?.programata && lucrare?.faza < 5) ||
                              (lucrare?.programata && lucrare?.faza === 1) ||
                              (isAdmin())) && (
                                <AiFillDelete className="deleteLucrareButton" onClick={() => setDeleteButtonPressed(true)} size={32} color="red" style={{ cursor: "pointer" }} />
                              )}
                          </Col>

                          <Col xs={4}>
                            <ButtonComponent
                              text={"Modifica"}
                              onClick={() => {
                                updateLucrare();
                              }}
                              disabled={blocking}
                              variant={"warning"}
                            />
                          </Col>
                          {(lucrare != null && !lucrare?.programata) && (
                              <Col xs={2}>
                               
                                  <div
                                    className="lucrare_muta_data_wrapper ml-4"
                                    onClick={() => {
                                      setMutaModal(true);
                                    }}
                                  >
                                    Muta
                                  </div>
                              </Col>)}
                          <Col xs={2}>
                          {(lucrare != null && !lucrare?.programata) && (
                            <div
                              className="lucrare_muta_data_wrapper ml-2"
                              onClick={() => {
                                setDataModal(true);
                              }}
                            >
                              Data
                            </div>)}
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      {locationType !== "notificari" && locationType !== "programate" && locationType !== "programateImportante" &&
                        (isDispecer() || isAdmin()) ? (
                        <>
                          <Row className="mb-3">
                            <Col className="lucare_faze_dispecer">
                              <div
                                className="mb-2 lucrare_dispecer_faza4"
                                onClick={() => {if(!blocking) addLucrare(4)}}
                              ></div>
                              <div
                                className="mb-2 lucrare_dispecer_faza3"
                                onClick={() => {if(!blocking)addLucrare(3)}}
                              ></div>
                              <div
                                className="mb-2 lucrare_dispecer_faza2"
                                onClick={() => {if(!blocking)addLucrare(2)}}
                              ></div>
                              <div
                                className="mb-2 lucrare_dispecer_faza1"
                                onClick={() => {if(!blocking)addLucrare(1)}}
                              ></div>
                              {isLocatieCar(lucrare?.locatie?.label) && (
                                <div
                                  className="mb-2 lucrare_dispecer_alimentare_combustibil text-center"
                                  onClick={() =>
                                    {if(!blocking)addLucrareAlimentareConbustibil()}
                                  }
                                >
                                  Alimentare combustibil
                                </div>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div className="modificare_stergere_wrapper">
                          <Row className="modificare_stergere_row">
                            {((!lucrare?.programata && lucrare?.faza < 5) ||
                              (lucrare?.programata && lucrare?.faza === 1) ||
                              (lucrareId && isAdmin())) && (
                                <Col className="text-center pl-0">
                                  <div
                                    onClick={deleteLucrare}
                                    className="deleteLucrareButton"
                                  >
                                    S
                                  </div>
                                </Col>
                              )}
                            <Col className="text-center">
                              <ButtonComponent
                                text={lucrareId ? "Modifica" : "Adauga"}
                                onClick={() => {
                                  if (lucrareId) {
                                    updateLucrare();
                                  } else {
                                    addLucrare(1);
                                  }
                                }}
                                disabled={blocking}
                                variant={lucrareId ? "warning" : "primary"}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {lucrareId ? (
                    <>
                      <div className="modificare_stergere_wrapper">
                        <Row className="modificare_stergere_row">
                          {(lucrare?.faza < 5 ||
                            (isAdmin())) && (
                              <Col className="text-center pl-0">
                                <div
                                  onClick={deleteLucrare}
                                  className="deleteLucrareButton"
                                >
                                  S
                                </div>
                              </Col>
                            )}
                          <Col className="text-center">
                            <ButtonComponent
                              text={"Modifica"}
                              onClick={() => {
                                updateLucrare();
                              }}
                              variant={"warning"}
                              disabled={blocking}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row className="mb-3">
                        <Col className="lucare_faze_dispecer">
                          {locationType === "ascensoare" && (
                            <div
                              className="mb-2 lucrare_dispecer_faza4_rosu"
                              onClick={() => addInterventieRosie(4)}
                            >
                              Persoana blocata
                            </div>
                          )}
                          <div
                            className="mb-2 lucrare_dispecer_faza4"
                            onClick={() => addLucrare(4)}
                          ></div>
                          <div
                            className="mb-2 lucrare_dispecer_faza3"
                            onClick={() => addLucrare(3)}
                          ></div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Lucrare;
