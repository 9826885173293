import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TextAreaComponent from "../components/basic/TextAreaComponent";

import { addObservatieLucrareService } from "../backend/ObservatieService";
import {
  addFacturaService,
  changeFazaLucrareService,
  updateLucrareService,
} from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";
import ButtonComponent from "../components/basic/ButtonComponent";
import DropDownComponent from "../components/basic/DropDownComponent";
import { editLocationService } from "../backend/LocationService";
import { mapMonth, isLocatieCar, showDateFormatFullYear } from "../utils/Utils";
import { months } from "../utils/Values";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';

const FinalizareLucrare = ({
  lucrare,
  closeModalFinalizare,
  setTab,
  refreshLucrari,
}) => {
  const serieOptions = [
    { value: "STIA", name: "STIA" },
    { value: "IESC", name: "IESC" },
    { value: "GE", name: "GE" },
    { value: "IL", name: "IL" },
    { value: "LL", name: "LL" },
    { value: "LS", name: "LS" },
    { value: "SLC", name: "SLC" }
  ];
  const { refreshObservatiiLucrare, refreshNumere, blocking, setBlocking } =
    useContext(RefreshContext);
  const [descriereLocatie, setDescriereLocatie] = useState(null);
  const [descriereInstSt, setDescriereInstSt] = useState(
    lucrare.locatieDTO.instSt
  );
  const [descriereInstHid, setDescriereInstHid] = useState(
    lucrare.locatieDTO.instHid
  );

  const [withFactura, setWithFactura] = useState(false);
  const [descriereLocatieCheck, setDescriereLocatieCheck] = useState(false);
  const [factura, setFactura] = useState();
  const [observatie, setObservatie] = useState();
  const [error, setError] = useState(false);
  const [errorIscir, setErrorIscir] = useState(false);
  const [errorFactura, setErrorFactura] = useState(false);
  const [errorObservatie, setErrorObservatie] = useState(false);

  const [errorITP, setErrorITP] = useState(false);
  const [errorRCA, setErrorRCA] = useState(false);
  const [errorParcare, setErrorParcare] = useState(false);
  const [errorRovigneta, setErrorRovigneta] = useState(false);
  const [errorAlimentare, setErrorAlimentare] = useState(false);

  const [itp, setItp] = useState();
  const [rca, setRca] = useState();
  const [parcare, setParcare] = useState();
  const [rovigneta, setRovigneta] = useState();
  const [nrKm, setNrKm] = useState();
  const [combustibil, setcombustibil] = useState();

  const [iscirTermen, setIscirTermen] = useState(null);

  const getNextScadentaHid = () => {
    if (lucrare && lucrare.locatieDTO.terHid) {
      let nextMonth = lucrare.locatieDTO.terHid + 6;

      if (nextMonth > 12) return nextMonth - 12;
      else return nextMonth;
    } else return null;
  };
  const [terHid, setTerHid] = useState({
    value:
      lucrare && lucrare.terHid
        ? getNextScadentaHid()
        : lucrare.locatieDTO.terHid,
    label: mapMonth(
      lucrare && lucrare.terHid
        ? getNextScadentaHid()
        : lucrare.locatieDTO.terHid
    ),
  });
  const [terSt, setTerSt] = useState({
    value: lucrare.locatieDTO.terSt,
    label: mapMonth(lucrare.locatieDTO.terSt),
  });

  useEffect(() => {
    registerLocale('ro', ro);
  });

  useEffect(() => {
    if (
      lucrare.lucrareType === "diverse" ||
      lucrare.lucrareType === "sediu" ||
      lucrare.lucrareType === "revizii" ||
      lucrare.lucrareType === "administrativ" ||
      lucrare.lucrareType === "comenzi" ||
      lucrare.lucrareType === "notificari" ||
      lucrare.lucrareType === "iscir" ||
      lucrare.lucrareType === "oferte"
    )
      setWithFactura(true);

    if (lucrare && lucrare.lucrareType === "psi") {
      if (lucrare.terHid) {
        setDescriereInstHid(lucrare.locatieDTO.instHid);
      } else if (lucrare.terSt) {
        setDescriereInstSt(lucrare.locatieDTO.instSt);
      } else setDescriereLocatie(lucrare.locatieDTO.descriere);
    } else {
      setDescriereLocatie(lucrare.locatieDTO.descriere);
    }
  }, []);

  const finalizareLucrare = () => {
    if (
      lucrare.lucrareType === "diverse" ||
      lucrare.lucrareType === "oferte" ||
      lucrare.lucrareType === "revizii" ||
      lucrare.lucrareType === "administrativ" ||
      (lucrare.lucrareType === "sediu" &&
        !isLocatieCar(lucrare.locatieDTO.locatie)) ||
      (lucrare.lucrareType === "sediu" && !lucrare.tipSediu) ||
      lucrare.lucrareType === "comenzi" ||
      lucrare.lucrareType === "notificari"
    ) {
      finalizareLucrareDiverse();
    } else {
      if (lucrare.lucrareType === "iscir") {
        finalizareLucrareIscir();
      } else if (
        lucrare.lucrareType === "sediu" &&
        isLocatieCar(lucrare.locatieDTO.locatie)
      ) {
        finalizareLucrareSediuMasini();
      } else {
        finalizareLucrareGeneral();
      }
    }
  };
  const finalizareLuc = async () => {
    if (observatie) {
      await addObservatieLucrareService(lucrare.uid, observatie);
    }

    if (lucrare.tipSediu === "ALIMENTARE") {
      await updateLucrareService(
        {
          ...lucrare,
          nrKm,
          combustibil,
        },
        lucrare?.locatieDTO.id
      );
    } else if (lucrare.tipSediu !== "REVIZIE") {
      await editLocationService(lucrare.locatieDTO.id, {
        ...lucrare.locatieDTO,
        rca: lucrare.tipSediu === "RCA" ? rca : lucrare.locatieDTO.rca,
        itp: lucrare.tipSediu === "ITP" ? itp : lucrare.locatieDTO.itp,
        parcare: lucrare.tipSediu === "PARCARE" ? parcare: lucrare.locatieDTO.parcare,
        rovigneta:
          lucrare.tipSediu === "ROVIGNETA"
            ? rovigneta
            : lucrare.locatieDTO.rovigneta,
      });
    }
    await changeFazaLucrareService(lucrare.uid, 8);
    refreshObservatiiLucrare();
    refreshLucrari();
    refreshNumere();
    setTab("detalii");
    closeModalFinalizare();
  };
  const finalizareLucrareSediuMasini = async () => {
    try {
      setBlocking(true);
      switch (lucrare.tipSediu) {
        case "ALIMENTARE":
          if (!nrKm || !combustibil) {
            setErrorAlimentare(true);
          } else {
            await finalizareLuc();
          }
          break;
        case "PARCARE":
          if (!parcare) {
            setErrorParcare(true);
          } else {
            await finalizareLuc();
          }
          break;
        case "ITP":
          if (!itp) {
            setErrorITP(true);
          } else {
            await finalizareLuc();
          }
          break;
        case "RCA": {
          if (!rca) {
            setErrorRCA(true);
          } else {
            await finalizareLuc();
          }
          break;
        }
        case "ROVIGNETA":
          if (!rovigneta) {
            setErrorRovigneta(true);
          } else {
            await finalizareLuc();
          }
          break;
        case "REVIZIE":
          await finalizareLuc();
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const finalizareLucrareDiverse = async () => {
    try {
      setBlocking(true);
      if (observatie) {
        await addObservatieLucrareService(lucrare.uid, observatie);
      }
      await changeFazaLucrareService(lucrare.uid, 8);

      refreshObservatiiLucrare();
      refreshLucrari();
      refreshNumere();
      setTab("detalii");
      closeModalFinalizare();
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };
  const finalizareLucrareGeneral = async () => {
    try {
      setBlocking(true);
      if (withFactura) {
        if (descriereLocatieCheck) {
          if (observatie) {
            await addObservatieLucrareService(lucrare.uid, observatie);
          }
          await changeFazaLucrareService(lucrare.uid, 8);

          await editLocationService(lucrare.locatieDTO.id, {
            locatie: lucrare.locatieDTO.locatie,
            client: lucrare.locatieDTO.client,
            descriere: descriereLocatie,
            adresa: lucrare.locatieDTO.adresa,
            persoana: lucrare.locatieDTO.persoana,
            tel: lucrare.locatieDTO.tel,
            email: lucrare.locatieDTO.email,
            contract: lucrare.locatieDTO.contract,
            scadenta: lucrare.locatieDTO.scadenta,
            cui: lucrare.locatieDTO.cui,
            type: lucrare.locatieDTO.type,
            firma: lucrare.locatieDTO.firma,
            zona: lucrare.locatieDTO.zona,
            instHid: descriereInstHid,
            instSt: descriereInstSt,
            terHid: terHid.value,
            terSt: terSt.value,
            terIscir: lucrare.locatieDTO.terIscir
          });

          refreshObservatiiLucrare();
          refreshLucrari();
          refreshNumere();
          setTab("detalii");
          closeModalFinalizare();
        } else {
          setError(true);
        }
      } else {
        if (
          descriereLocatieCheck &&
          factura &&
          factura.serie &&
          factura.numar
        ) {
          if (observatie) {
            await addObservatieLucrareService(lucrare.uid, observatie);
          }
          if (!withFactura) {
            if (factura && factura.serie && factura.numar) {
              await addFacturaService(lucrare.uid, factura);
              await changeFazaLucrareService(lucrare.uid, 8);

              await editLocationService(lucrare.locatieDTO.id, {
                locatie: lucrare.locatieDTO.locatie,
                client: lucrare.locatieDTO.client,
                descriere: descriereLocatie,
                type: lucrare.locatieDTO.type,
                adresa: lucrare.locatieDTO.adresa,
                persoana: lucrare.locatieDTO.persoana,
                tel: lucrare.locatieDTO.tel,
                email: lucrare.locatieDTO.email,
                contract: lucrare.locatieDTO.contract,
                scadenta: lucrare.locatieDTO.scadenta,
                cui: lucrare.locatieDTO.cui,
                firma: lucrare.locatieDTO.firma,
                zona: lucrare.locatieDTO.zona,
                instHid: descriereInstHid,
                instSt: descriereInstSt,
                terHid: terHid.value,
                terSt: terSt.value,
                terIscir: lucrare.locatieDTO.terIscir
              });

              refreshObservatiiLucrare();
              refreshLucrari();
              refreshNumere();
              setTab("detalii");
              closeModalFinalizare();
            } else {
              setError(true);
            }
          } else {
            await changeFazaLucrareService(lucrare.uid, 8);

            await editLocationService(lucrare.locatieDTO.id, {
              locatie: lucrare.locatieDTO.locatie,
              client: lucrare.locatieDTO.client,
              descriere: descriereLocatie,
              type: lucrare.locatieDTO.type,
              adresa: lucrare.locatieDTO.adresa,
              persoana: lucrare.locatieDTO.persoana,
              tel: lucrare.locatieDTO.tel,
              email: lucrare.locatieDTO.email,
              contract: lucrare.locatieDTO.contract,
              scadenta: lucrare.locatieDTO.scadenta,
              cui: lucrare.locatieDTO.cui,
              firma: lucrare.locatieDTO.firma,
              zona: lucrare.locatieDTO.zona,
              instHid: descriereInstHid,
              instSt: descriereInstSt,
              terHid: terHid.value,
              terSt: terSt.value,
              terIscir: lucrare.locatieDTO.terIscir
            });

            refreshObservatiiLucrare();
            refreshLucrari();
            refreshNumere();
            setTab("detalii");
            closeModalFinalizare();
          }
        } else {
          if (!descriereLocatieCheck) {
            setError(true);
          }
          if (!(factura && factura.serie && factura.numar))
            setErrorFactura(true);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const finalizareLucrareIscir = async () => {
    try {
      setBlocking(true);
      if (withFactura) {
        if (descriereLocatieCheck) {
          if (lucrare.fazaIscir === 2) {
            if (iscirTermen) {
              if (observatie) {
                await addObservatieLucrareService(lucrare.uid, observatie);
              }
              await changeFazaLucrareService(lucrare.uid, 8);
              await editLocationService(lucrare.locatieDTO.id, {
                locatie: lucrare.locatieDTO.locatie,
                client: lucrare.locatieDTO.client,
                descriere: descriereLocatie,
                adresa: lucrare.locatieDTO.adresa,
                persoana: lucrare.locatieDTO.persoana,
                tel: lucrare.locatieDTO.tel,
                email: lucrare.locatieDTO.email,
                contract: lucrare.locatieDTO.contract,
                scadenta: lucrare.locatieDTO.scadenta,
                cui: lucrare.locatieDTO.cui,
                type: lucrare.locatieDTO.type,
                firma: lucrare.locatieDTO.firma,
                zona: lucrare.locatieDTO.zona,
                instHid: descriereInstHid,
                instSt: descriereInstSt,
                terHid: terHid.value,
                terSt: terSt.value,
                terIscir: iscirTermen,
              });

              refreshObservatiiLucrare();
              refreshLucrari();
              refreshNumere();
              setTab("detalii");
              closeModalFinalizare();
            } else {
              setErrorIscir(true);
            }
          } else {
            if (observatie) {
              await addObservatieLucrareService(lucrare.uid, observatie);
            }
            await changeFazaLucrareService(lucrare.uid, 8);
            await editLocationService(lucrare.locatieDTO.id, {
              locatie: lucrare.locatieDTO.locatie,
              client: lucrare.locatieDTO.client,
              descriere: descriereLocatie,
              adresa: lucrare.locatieDTO.adresa,
              persoana: lucrare.locatieDTO.persoana,
              tel: lucrare.locatieDTO.tel,
              email: lucrare.locatieDTO.email,
              contract: lucrare.locatieDTO.contract,
              scadenta: lucrare.locatieDTO.scadenta,
              cui: lucrare.locatieDTO.cui,
              type: lucrare.locatieDTO.type,
              firma: lucrare.locatieDTO.firma,
              zona: lucrare.locatieDTO.zona,
              instHid: descriereInstHid,
              instSt: descriereInstSt,
              terHid: terHid.value,
              terSt: terSt.value,
              terIscir: iscirTermen,
            });

            refreshObservatiiLucrare();
            refreshLucrari();
            refreshNumere();
            setTab("detalii");
            closeModalFinalizare();
          }
        } else {
          setError(true);
        }
      } else {
        if (
          descriereLocatieCheck &&
          factura &&
          factura.serie &&
          factura.numar
        ) {
          if (observatie) {
            await addObservatieLucrareService(lucrare.uid, observatie);
          }
          if (!withFactura) {
            if (factura && factura.serie && factura.numar) {
              await addFacturaService(lucrare.uid, factura);
              await changeFazaLucrareService(lucrare.uid, 8);
              await editLocationService(lucrare.locatieDTO.id, {
                locatie: lucrare.locatieDTO.locatie,
                client: lucrare.locatieDTO.client,
                descriere: descriereLocatie,
                type: lucrare.locatieDTO.type,
                adresa: lucrare.locatieDTO.adresa,
                persoana: lucrare.locatieDTO.persoana,
                tel: lucrare.locatieDTO.tel,
                email: lucrare.locatieDTO.email,
                contract: lucrare.locatieDTO.contract,
                scadenta: lucrare.locatieDTO.scadenta,
                cui: lucrare.locatieDTO.cui,
                firma: lucrare.locatieDTO.firma,
                zona: lucrare.locatieDTO.zona,
                instHid: descriereInstHid,
                instSt: descriereInstSt,
                terHid: terHid.value,
                terSt: terSt.value,
                terIscir: iscirTermen,
              });

              refreshObservatiiLucrare();
              refreshLucrari();
              refreshNumere();
              setTab("detalii");
              closeModalFinalizare();
            } else {
              setError(true);
            }
          } else {
            await changeFazaLucrareService(lucrare.uid, 8);
            await editLocationService(lucrare.locatieDTO.id, {
              locatie: lucrare.locatieDTO.locatie,
              client: lucrare.locatieDTO.client,
              descriere: descriereLocatie,
              type: lucrare.locatieDTO.type,
              adresa: lucrare.locatieDTO.adresa,
              persoana: lucrare.locatieDTO.persoana,
              tel: lucrare.locatieDTO.tel,
              email: lucrare.locatieDTO.email,
              contract: lucrare.locatieDTO.contract,
              scadenta: lucrare.locatieDTO.scadenta,
              cui: lucrare.locatieDTO.cui,
              firma: lucrare.locatieDTO.firma,
              zona: lucrare.locatieDTO.zona,
              instHid: descriereInstHid,
              instSt: descriereInstSt,
              terHid: terHid.value,
              terSt: terSt.value,
              terIscir: iscirTermen,
            });

            refreshObservatiiLucrare();
            refreshLucrari();
            refreshNumere();
            setTab("detalii");
            closeModalFinalizare();
          }
        } else {
          if (!descriereLocatieCheck) {
            setError(true);
          }
          if (!(factura && factura.serie && factura.numar))
            setErrorFactura(true);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const revenireLucrare = async (faza) => {
    try {
      setBlocking(true);
      if (!observatie || observatie?.text?.length < 2) {
        setErrorObservatie(true);
      } else {
        await addObservatieLucrareService(lucrare.uid, observatie);

        await changeFazaLucrareService(lucrare.uid, faza);
        closeModalFinalizare();
        refreshNumere();
        refreshLucrari();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const getDescriere = () => {
    if (lucrare && lucrare.lucrareType === "psi") {
      if (lucrare.terSt) return descriereInstSt;
      else if (lucrare.terHid) return descriereInstHid;
      else return descriereLocatie;
    } else return descriereLocatie;
  };

  const setDescriereFunc = (value) => {
    if (lucrare && lucrare.lucrareType === "psi") {
      if (lucrare.terSt) {
        setDescriereInstSt(value);
      } else if (lucrare.terHid) {
        setDescriereInstHid(value);
      } else {
        setDescriereLocatie(value);
      }
    } else setDescriereLocatie(value);
  };

  return (
    <>
      <Row className="text-center">
        <Col className={"mb-2 lucrare_finalizare_descriere_wrapper"}>
          <div className="lucrare_finalizare_descriere">
            {lucrare.locatieDTO.locatie}
          </div>
        </Col>
      </Row>
      <Row className="text-center">
        <Col className={"mb-4 lucrare_finalizare_descriere_wrapper"}>
          <div className="lucrare_finalizare_descriere">
            {lucrare.descriere}
          </div>
        </Col>
      </Row>
      {lucrare.lucrareType !== "diverse" &&
        lucrare.lucrareType !== "oferte" &&
        lucrare.lucrareType !== "sediu" &&
        lucrare.lucrareType !== "notificari" &&
        lucrare.lucrareType !== "comenzi" &&
        lucrare.lucrareType !== "administrativ" && (
          <>
            {lucrare.lucrareType !== "revizii" &&
              lucrare.lucrareType !== "iscir" && (
                <Row className="mb-3">
                  <Col className="text-center">
                    <div className="factura_wrapper">
                      <div className="mr-2">Fara facturare</div>
                      <input
                        className={errorFactura ? "error_factura mr-2" : "mr-2"}
                        type="checkbox"
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        checked={withFactura}
                        onChange={() => setWithFactura(!withFactura)}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            {!withFactura && (
              <Row className="mb-4">
                <Col className="text-center serie_nr_wrapper">
                  <div className={errorFactura ? "error_factura mr-2" : "mr-2"}>
                    <DropDownComponent
                      variant={"secondary"}
                      data={serieOptions}
                      dropdownDefaulText="Seria"
                      // icon="fa fa-location-arrow"
                      onChange={(value) => {
                        setFactura({ ...factura, serie: value });
                        if (factura && factura.numar) setErrorFactura(false);
                      }}
                    />
                  </div>
                  <div className={errorFactura ? "error_factura ml-2" : "ml-2"}>
                    <input
                      className="factura_nr_input"
                      placeholder="Numarul"
                      value={factura ? factura.numar : ""}
                      onChange={(e) => {
                        setFactura({ ...factura, numar: e.target.value });
                        if (factura && factura.serie) setErrorFactura(false);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <Row className="mb-3">
              <Col xs={11}>
                <TextAreaComponent
                  placeholder="Instalatie"
                  value={getDescriere()}
                  onChange={(e) => setDescriereFunc(e.target.value)}
                  rows={3}
                  cols={55}
                  className={error ? "error_descriere_instalatie" : ""}
                />
              </Col>
              {lucrare.lucrareType !== "revizii" &&
              (
              <Col xs={1} className="p-0">
                <div>
                  <input
                    className="d-inline-block mt-4"
                    type="checkbox"
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                    checked={descriereLocatieCheck}
                    onChange={() => {
                      setDescriereLocatieCheck(!descriereLocatieCheck);
                      setError(false);
                    }}
                  />
                </div>
              </Col>)}
            </Row>
          </>
        )}
      {lucrare && lucrare.lucrareType === "psi" && (
        <Row className="mb-3">
          <Col>
            <div className="lucrare_item_muta_scadenta_wrapper">
              {lucrare.terHid && (
                <div className="lucrare_item_muta_scadenta">
                  <CreatableSelect
                    placeholder={"Scadenta"}
                    // styles={customStyles}
                    formatCreateLabel={() => null || undefined}
                    noOptionsMessage={() => null}
                    isClearable
                    isLoading={false}
                    isDisabled={false}
                    onBlurResetsInput={false}
                    onChange={(value) => {
                      // setLocation({ ...location, terSt: value });
                      // setError("");
                      setTerHid(value);
                    }}
                    options={months}
                    value={terHid}
                  />
                </div>
              )}
              {lucrare.terSt && (
                <div className="lucrare_item_muta_scadenta">
                  <CreatableSelect
                    placeholder={"Scadenta"}
                    // styles={customStyles}
                    formatCreateLabel={() => null || undefined}
                    noOptionsMessage={() => null}
                    isClearable
                    isLoading={false}
                    isDisabled={false}
                    onBlurResetsInput={false}
                    onChange={(value) => {
                      // setLocation({ ...location, terSt: value });
                      // setError("");
                      setTerSt(value);
                    }}
                    options={months}
                    value={terSt}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
      {lucrare && lucrare.lucrareType === "iscir" && lucrare.fazaIscir === 2 && (
        <div className="locatieInputWrapper mb-3">
          <p>Scadenta</p>
          <div
            className={
              errorIscir
                ? "scadenta_iscir_finalizare_lucrare_error"
                : "scadenta_iscir_finalizare_lucrare"
            }
          >
             <DatePicker
                locale="ro"
                className="input_locatie"
                dateFormat="dd/MM/yyyy"
                selected={null}
                value={
                  iscirTermen
                    ? `${showDateFormatFullYear(
                      iscirTermen
                    )}`
                    : "Data"
                }
                minDate={new Date()}
                onChange={(date) => {
                  setErrorIscir(false);
                  setIscirTermen(date)
                }}
              />
          </div>
        </div>
      )}
      <Row className="mb-4">
        <Col className="text-center">
          {lucrare.tipSediu === "ITP" && (
            <div className="finalizare_lucrare_masini_wrapper">
              <div className="mr-3">Inspectie ITP</div>
              <div className={errorITP ? "error_factura" : ""}>
                <DatePicker
                  locale="ro"
                  dateFormat="dd/MM/yyyy"
                  selected={itp ? new Date(itp) : new Date()}
                  value={itp ? `${showDateFormatFullYear(itp)}` : ``}
                  onChange={(date) => {
                    setItp(date);
                    if (errorITP) setErrorITP(false);
                  }}
                />
              </div>
            </div>
          )}
             {lucrare.tipSediu === "PARCARE" && (
            <div className="finalizare_lucrare_masini_wrapper">
              <div className="mr-3">Parcare</div>
              <div className={errorParcare ? "error_factura" : ""}>
                <DatePicker
                  locale="ro"
                  dateFormat="dd/MM/yyyy"
                  selected={parcare ? new Date(parcare) : new Date()}
                  value={parcare ? `${showDateFormatFullYear(parcare)}` : ``}
                  onChange={(date) => {
                    setParcare(date);
                    if (errorParcare) setErrorParcare(false);
                  }}
                />
              </div>
            </div>
          )}
          {lucrare.tipSediu === "RCA" && (
            <div className="finalizare_lucrare_masini_wrapper">
              <div className="mr-3">Asigurare RCA</div>
              <div className={errorRCA ? "error_factura" : ""}>
                <DatePicker
                  locale="ro"
                  dateFormat="dd/MM/yyyy"
                  selected={rca ? new Date(rca) : new Date()}
                  value={rca ? `${showDateFormatFullYear(rca)}` : ``}
                  onChange={(date) => {
                    setRca(date);
                    if (errorRCA) setErrorRCA(false);
                  }}
                />
              </div>
            </div>
          )}
          {lucrare.tipSediu === "ROVIGNETA" && (
            <div className="finalizare_lucrare_masini_wrapper">
              <div className="mr-3">Rovigneta</div>
              <div className={errorRovigneta ? "error_factura" : ""}>
                <DatePicker
                  locale="ro"
                  dateFormat="dd/MM/yyyy"
                  selected={rovigneta ? new Date(rovigneta) : new Date()}
                  value={
                    rovigneta ? `${showDateFormatFullYear(rovigneta)}` : ``
                  }
                  onChange={(date) => {
                    setRovigneta(date);
                    if (errorRovigneta) setErrorRovigneta(false);
                  }}
                />
              </div>
            </div>
          )}

          {lucrare.tipSediu === "ALIMENTARE" && (
            <div className="finalizare_lucrare_masini_wrapper">
              <div className="finalizare_lucrare_km_wrapper">
                <div className="finalizare_lucrare_alimentare_text">Km</div>
                <input
                  className={
                    errorAlimentare ? "error_factura" : "finalizare_lucrare_km"
                  }
                  type="number"
                  value={nrKm ? nrKm : ""}
                  onChange={(e) => setNrKm(e.target.value)}
                />
              </div>
              <div className="finalizare_lucrare_litrii_wrapper">
                <div className="finalizare_lucrare_alimentare_text">Litri</div>
                <input
                  className={
                    errorAlimentare
                      ? "error_factura"
                      : "finalizare_lucrare_litrii"
                  }
                  type="number"
                  value={combustibil ? combustibil : ""}
                  onChange={(e) => setcombustibil(e.target.value)}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TextAreaComponent
            className={errorObservatie ? "descriereError" : ""}
            placeholder="Observatie"
            value={observatie ? observatie.text : ""}
            onChange={(e) => {
              setObservatie({ ...observatie, text: e.target.value });
              setErrorObservatie(false);
            }}
            rows={3}
            cols={55}
            autoFocus
          />
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <div className="text-center">
            <div className="mb-2">
              <ButtonComponent
                color="button_finalizare_lucrare"
                text="Finalizare"
                onClick={finalizareLucrare}
                setTab={setTab}
                disabled={blocking}
              />
            </div>
            <div>
              <ButtonComponent
                variant="success"
                text="Revenire"
                onClick={() => revenireLucrare(6)}
                disabled={blocking}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FinalizareLucrare;
