// lucrarile (folders) ce au poze
import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import "../style/LucrareItem.css";
import { downloadLocatieDocument } from "../backend/FileUploadService";
import { convertDateToFullYear } from "../utils/Utils";

const DocumentLocatieItem = ({
  documentData,
  locationType,
  locationFolderName,
  setDocumentToDelete
}) => {

  const downloadDocument = async () => {
    const response = await downloadLocatieDocument(locationType, locationFolderName, documentData.fileName);
    // Create a URL for the blob data received
    const documentUrl = window.URL.createObjectURL(new Blob([response]));

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = documentUrl;
    link.setAttribute('download', documentData.originalFileName); // Set filename for the download
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup: remove the temporary link element
    document.body.removeChild(link);
  }

  return (
    <Row
      className={
        "mb-1"
      }
    >
      <Row
        className="row_folder"
      >

        <Col className="lucrare_item_locatie" style={{ background: "#ffe7b3" }} md={4}>
          <Row>{documentData?.date? convertDateToFullYear(documentData?.date) : ""}</Row>
          <Row>{documentData?.username?.toUpperCase() || ""}</Row>
        </Col>

        <Col
          onClick={() => {
            downloadDocument();
          }}
          className="lucrare_folder_data"
          style={{ background: "#87cefa" }}
          md={8}
          onContextMenu={(e) => { e.preventDefault(); setDocumentToDelete(documentData.fileName); }}
        >
          {documentData?.originalFileName || ""}
        </Col>
      </Row>
    </Row>
  );
};
export default DocumentLocatieItem;
