import React, { useContext, useState } from "react";
import ButtonComponent from "../components/basic/ButtonComponent";
import DeDiscutat from "./DeDiscutat";
import ModalComponent from "../components/basic/ModalComponent";
import ModalReminderCreate from "./ModalReminderCreate";
import { UserContext } from "../context/UserContext";
import { editCifruLocationService } from "../backend/LocationService";
import "../style/DeDiscutatReminderCifru.css";

const DeDiscutatReminderCifru = ({ closeModalDeDiscutatReminder,
  lucrareId,
  refreshLucrari,
  discutieId,
  hasLocatieCifru,
  locatieCifru,
  changeLocatieCifru,
  locatieId }) => {
  const [modalDeDiscutat, setModalDeDiscutat] = useState(false);
  const [modalReminder, setModalReminder] = useState(false);
  const [showChangeRequestCifruModal, setShowChangeRequestCifruModal] = useState(false);
  const [showCancelChangeRequestCifruModal, setShowCancelChangeRequestCifruModal] = useState(false);
  const [shownLocatieCifru, setShownLocatieCifru] = useState(locatieCifru);
  const [error, setError] = useState();
  const [changeLocatieCifruWrapper, setChangeLocatieCifruWrapper] = useState(changeLocatieCifru);
  const { isAdmin, isDispecer } = useContext(UserContext);

  const closeModalDeDiscutat = () => {
    setModalDeDiscutat(false);
  };

  const openModalDeDiscutat = () => {
    setModalDeDiscutat(true);
  };


  const closeModalReminder = () => {
    setModalReminder(false);
  };

  const openModalReminder = () => {
    setModalReminder(true);
  };

  const rightClickCifru = async (e) => {
    e.preventDefault();
    try {
      if ((isAdmin() || isDispecer()) && changeLocatieCifruWrapper == false) {
        setShowChangeRequestCifruModal(true);
      }
      else if ((isAdmin() || isDispecer()) && changeLocatieCifruWrapper == true) {
        setShowCancelChangeRequestCifruModal(true);
      }
    } catch (err) {
      console.log(err);
      setError("Eroare la schimbarea cifrului!");
    }
  }


  const requestNewCifruChange = async () => {
    await editCifruLocationService(locatieId, {
      cifru: shownLocatieCifru,
      hasCifru: true,
      cifruChange: true
    });
    setChangeLocatieCifruWrapper(true);
    setShowChangeRequestCifruModal(false);
    refreshLucrari();
  }

  const cancelRequestNewCifruChange = async () => {
    await editCifruLocationService(locatieId, {
      cifru: shownLocatieCifru,
      hasCifru: true,
      cifruChange: false
    });
    setChangeLocatieCifruWrapper(false);
    setShowCancelChangeRequestCifruModal(false);
    refreshLucrari();
  }

  return (
    <>
      {error == null && (
        <div className="text-center">
          <div className="mb-2">
            <ButtonComponent
              variant="primary"
              text="Reminder"
              onClick={() => openModalReminder()}
            />
          </div>
          <div>
            <ButtonComponent
              variant="primary"
              text="De Discutat"
              onClick={() => openModalDeDiscutat()}
            />
          </div>
          {hasLocatieCifru === true &&
            <div className="mb-2" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <div
                onContextMenu={(e) => rightClickCifru(e)}
                style={
                  {
                    marginTop: 10,
                    backgroundColor: "#007bff",
                    borderRadius: 5,
                    width: 125,
                    alignSelf: "center",
                    alignContent: "center",
                  }
                }
              >
                {changeLocatieCifruWrapper === true &&
                  <div style={{ color: "red", fontSize: 25 }}>
                    {shownLocatieCifru.split('').join('-')}
                  </div>}
                {changeLocatieCifruWrapper === false &&
                  <div style={{ color: "white", fontSize: 25 }}>
                    {shownLocatieCifru.split('').join('-')}
                  </div>}
              </div></div>}
          <ModalComponent
            size={"md"}
            show={modalDeDiscutat}
            close={closeModalDeDiscutat}
            headingText={"De Discutat"}
            body={
              <DeDiscutat
                closeModalDeDiscutat={closeModalDeDiscutat}
                closeModalDeDiscutatReminder={closeModalDeDiscutatReminder}
                lucrareId={lucrareId}
                refreshLucrari={refreshLucrari}
                discutieId={discutieId}
              />
            }
          />
          <ModalComponent
            size={"md"}
            show={modalReminder}
            close={closeModalReminder}
            headingText={"Reminder"}
            body={
              <ModalReminderCreate
                closeModalReminder={closeModalReminder}
                closeModalDeDiscutatReminder={closeModalDeDiscutatReminder}
                lucrareId={lucrareId}
                refreshLucrari={refreshLucrari}
                discutieId={discutieId}
              />
            }
          />
          <ModalComponent
            size={"md"}
            show={showChangeRequestCifruModal}
            close={() => setShowChangeRequestCifruModal(false)}
            headingText={""}
            body={
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ fontSize: 25, marginBottom: 10 }}>Inlocuire Cifru?</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="buttonWrapper">
                    <ButtonComponent
                      text="Nu"
                      onClick={() => setShowChangeRequestCifruModal(false)}
                    /></div>
                  <div className="buttonWrapper">
                    <ButtonComponent
                      text="Da"
                      onClick={() => requestNewCifruChange()}
                    /></div>
                </div>
              </div>
            }
          />
          <ModalComponent
            size={"md"}
            show={showCancelChangeRequestCifruModal}
            close={() => setShowCancelChangeRequestCifruModal(false)}
            headingText={""}
            body={
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ fontSize: 25, marginBottom: 10 }}>Anulare inlocuire cifru?</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="buttonWrapper">
                    <ButtonComponent
                      text="Nu"
                      onClick={() => setShowCancelChangeRequestCifruModal(false)}
                    /></div>
                  <div className="buttonWrapper">
                    <ButtonComponent
                      text="Da"
                      onClick={() => cancelRequestNewCifruChange()}
                    /></div>
                </div>
              </div>
            }
          />
        </div>)}
      {error != null && (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <div style={{ color: "red", fontSize: 40 }}>
            Eroare!
          </div>
        </div>
      )}
    </>
  );
};

export default DeDiscutatReminderCifru;
