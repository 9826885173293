import { doGet, doPost } from "./RESTUtils";

//lucrare
export const addObservatieLucrareService = async (lucrareId, observatie) => {
  return await doPost(`/Logics/lucrare/${lucrareId}/addObservatie`, observatie);
};

export const finishRevizie = async(lucrareId) =>{
  return await doGet(`/Logics/lucrare/finishRevizie/${lucrareId}`);
}

export const getObservatiiLucrareService = async lucrareId => {
  return await doGet(`/Logics/lucrare/${lucrareId}/getObservatii`);
};

export const updateObservatieLucrareService = async (observatie) => {
  return await doPost(`/Logics/lucrare/observatii/update`, observatie);
};

export const deleteObservatieLucrareService = async (observatieId, lucrareId) => {
  return await doGet(`/Logics/lucrare/deleteObservatie/${lucrareId}/${observatieId}`);
};

//locatie
export const addObservatieLocatieService = async (locatieId, observatie) => {
  return await doPost(`/Logics/locatie/${locatieId}/addObservatie`, observatie);
};

export const getObservatiiLocatieService = async locatieId => {
  return await doGet(`/Logics/locatie/${locatieId}/getObservatii`);
};

export const updateObservatieLocatieService = async (observatie) => {
  return await doPost(`/Logics/locatie/observatii/update`, observatie);
};

export const markAsReadService = async observatieId => {
  return await doGet(`/Logics/lucrare/observatii/${observatieId}/markAsRead`);
};
