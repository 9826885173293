export const listDispeceri = ["FG", "ZC", "ABD", "HV"];
export const listTehnicieni = ["AB", "AA", "NS", "ST"];

export const readLucrariTimer = 10000;
export const refreshTimer = 3000;
export const refreshNewMediaTimer = 5000;

export const cars = [
  "CS 09 LGS",
  "CS 10 LGS",
  "CS 11 LGS",
  "CS 12 LGS",
  "CS 13 LGS",
  "CS 14 LGS",
  "CS 19 LGS",
  "CS 20 LGS",
  "CS 21 LGS",
  "MASINA TEST",
];

export const months = [
  {
    value: 1,
    label: "Ian",
  },
  {
    value: 2,
    label: "Feb",
  },
  {
    value: 3,
    label: "Mar",
  },
  {
    value: 4,
    label: "Apr",
  },
  {
    value: 5,
    label: "Mai",
  },
  {
    value: 6,
    label: "Iun",
  },
  {
    value: 7,
    label: "Iul",
  },
  {
    value: 8,
    label: "Aug",
  },
  {
    value: 9,
    label: "Sep",
  },
  {
    value: 10,
    label: "Oct",
  },
  {
    value: 11,
    label: "Noi",
  },
  {
    value: 12,
    label: "Dec",
  },
];
