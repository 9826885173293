import React from "react";
import { Button } from "react-bootstrap";

const ButtonComponent = ({
  text,
  onClick,
  onContextMenu,
  disabled,
  hidden,
  variant,
  color
}) => {
  return (
    <div className="button_wrapper">
      <Button
        className={`button_button ${color}`}
        variant={variant}
        onClick={onClick}
        onContextMenu={onContextMenu}
        disabled={disabled}
        hidden={hidden}
      >
        {text}
      </Button>
    </div>
  );
};

export default ButtonComponent;
