import React, { useState, useEffect } from "react";
import { getRomanianDay, showDateFormat } from "../utils/Utils";
import DropDownComponent from "../components/basic/DropDownComponent";
import ButtonComponent from "../components/basic/ButtonComponent";
import { updatePontajService } from "../backend/PontajeService";

const Pontaj = ({ pontaj, refreshPontaje, closeModal }) => {
  const [ore, setOre] = useState("");
  const [interventie1, setInterventie1] = useState(false);
  const [interventie2, setInterventie2] = useState(false);
  const [telefon, setTelefon] = useState(false);
  const [telefon2, setTelefon2] = useState(false);
  const [suplimentare, setSuplimentare] = useState("");
  const [optiune, setOptiune] = useState(null);

  const oreOptions = [
    { value: "0", name: "0" },
    { value: "0.5", name: "0.5" },
    { value: "1", name: "1" },
    { value: "1.5", name: "1.5" },
    { value: "2", name: "2" },
    { value: "2.5", name: "2.5" },
    { value: "3", name: "3" },
    { value: "3.5", name: "3.5" },
    { value: "4", name: "4" },
    { value: "4.5", name: "4.5" },
    { value: "5", name: "5" },
    { value: "5.5", name: "5.5" },
    { value: "6", name: "6" },
    { value: "6.5", name: "6.5" },
    { value: "7", name: "7" },
    { value: "", name: "" },
    { value: "7.5", name: "7.5" },
    { value: "8", name: "8" },
    { value: "8.5", name: "8.5" },
    { value: "9", name: "9" },
    { value: "9.5", name: "9.5" },
    { value: "10", name: "10" },
    { value: "10.5", name: "10.5" },
    { value: "11", name: "11" },
    { value: "11.5", name: "11.5" },
    { value: "12", name: "12" },
    { value: "12.5", name: "12.5" },
    { value: "13", name: "13" },
    { value: "13.5", name: "13.5" },
    { value: "14", name: "14" },
    { value: "14.5", name: "14.5" },
    { value: "15", name: "15" },
    { value: "15.5", name: "15.5" },
    { value: "16", name: "16" }
  ];

  const oreSuplimentareOptions = [
    { value: "", name: "" },
    { value: "0", name: "0" },
    { value: "0.5", name: "0.5" },
    { value: "1", name: "1" },
    { value: "1.5", name: "1.5" },
    { value: "2", name: "2" },
    { value: "2.5", name: "2.5" },
    { value: "3", name: "3" },
    { value: "3.5", name: "3.5" },
    { value: "4", name: "4" },
    { value: "4.5", name: "4.5" },
    { value: "5", name: "5" },
    { value: "5.5", name: "5.5" },
    { value: "6", name: "6" },
    { value: "6.5", name: "6.5" },
    { value: "7", name: "7" },
    { value: "7.5", name: "7.5" },
    { value: "8", name: "8" }
  ];
  const dispecerOptions = [
    { value: "CO", name: "CO" },
    { value: "ZL", name: "ZL" },
    { value: "CM", name: "CM" },
    { value: "ABN", name: "AbN" }
  ];

  const isValid = () => {
    return (
      ore === null &&
      suplimentare === null &&
      !interventie1 &&
      !interventie2 &&
      !telefon &&
      !telefon2 &&
      optiune === null
    );
  };

  useEffect(() => {
    if (pontaj.ore !== null) {
      setOre(String(pontaj.ore));
    }
    if (pontaj.optiune !== "NONE") setOptiune(pontaj.optiune);
    setInterventie1(pontaj.interventie1);
    setInterventie2(pontaj.interventie2);
    setTelefon(pontaj.telefon);
    setTelefon2(pontaj.telefon2);
    if (pontaj.suplimentare !== null)
      setSuplimentare(String(pontaj.suplimentare));
  }, [pontaj]);

  const updatePontaj = async () => {
    try {
      if (isValid()) {
        return;
      } else {
        await updatePontajService(pontaj.id, {
          ore,
          interventie1,
          interventie2,
          suplimentare,
          telefon,
          telefon2,
          optiune
        });
        closeModal();
        refreshPontaje();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="pontaj_tehnician_wrapper">
      <div className="ponaj_tehnian_nume">
        {pontaj.nume} - {pontaj.user.toUpperCase()}
      </div>
      <div className="ponaj_tehnian_data">
        {getRomanianDay(new Date(pontaj.date))}
        {" "}
        {showDateFormat(new Date(pontaj.date))}
      </div>
      <div className="ponaj_tehnian_ore_lucrate">
        <div className="pontaj_label_text">Ore Lucrate</div>
        <div className="drop_down_secondary_wrapper">
          <DropDownComponent
            customClassMenu={"dropdown_ore_lucrate"}
            variant={"secondary"}
            data={oreOptions}
            defaultValue={ore === "0" || ore === "" ? "" : ore}
            dropdownDefaulText=""
            onChange={value => {
              setOptiune(null);
              if (String(value) === "0") setOre("");
              else setOre(String(value));
            }}
          />
        </div>
      </div>
      <div className="ponaj_tehnian_ore_interventii">
        <div className="pontaj_label_text">Interventii</div>
        <div>
          <input
            className="d-inline-block ponaj_tehnian_ore_interventii_1"
            type="checkbox"
            checked={optiune === null ? interventie1 : false}
            onChange={() => {
              setOptiune(null);
              setInterventie1(!interventie1);
            }}
          />
          <input
            className="d-inline-block ponaj_tehnian_ore_interventii_2"
            type="checkbox"
            checked={optiune === null ? interventie2 : false}
            onChange={() => {
              setOptiune(null);
              setInterventie2(!interventie2);
            }}
          />
        </div>
      </div>
      <div className="ponaj_tehnian_ore_interventii">
        <div className="pontaj_label_text">Telefon</div>
        <div>
          <input
            className="d-inline-block ponaj_tehnian_ore_interventii_1"
            type="checkbox"
            checked={optiune === null ? telefon : false}
            onChange={() => {
              setOptiune(null);
              setTelefon(!telefon);
            }}
          />
          <input
            className="d-inline-block ponaj_tehnian_ore_interventii_2"
            type="checkbox"
            checked={optiune === null ? telefon2 : false}
            onChange={() => {
              setOptiune(null);
              setTelefon2(!telefon2);
            }}
          />
        </div>
      </div>
      <div className="ponaj_tehnian_ore_suplimentare">
        <div className="pontaj_label_text">Ore suplimentare</div>
        <div className="drop_down_secondary_wrapper">
          <DropDownComponent
            variant={"secondary"}
            customClassMenu={"dropdown_ore_lucrate"}
            data={oreSuplimentareOptions}
            defaultValue={
              suplimentare === "0" || suplimentare === "" ? "" : suplimentare
            }
            dropdownDefaulText=""
            onChange={value => {
              setOptiune(null);
              if (String(value) === "0") setSuplimentare("");
              else setSuplimentare(String(value));
            }}
          />
        </div>
      </div>
      <div className="ponaj_tehnian_ore_dispecer_options">
        <DropDownComponent
          customClass={"dropdown_dispecer_options"}
          customClassButton={"dropdown_dispecer_options_button"}
          customClassMenu={"dropdown_dispecer_menu"}
          variant={"secondary"}
          data={dispecerOptions}
          defaultValue={optiune}
          dropdownDefaulText="CO - ZL - CM - AbN"
          onChange={value => {
            setOre(null);
            setSuplimentare(null);
            setInterventie1(false);
            setInterventie2(false);
            setTelefon(false);
            setTelefon2(false);
            setOptiune(value);
          }}
        />
      </div>
      <div className="ponaj_tehnian_ore_validare">
        <ButtonComponent
          text="VALIDARE"
          onClick={updatePontaj}
          color={
            isValid()
              ? "ponaj_tehnian_ore_validare_btton_not_valid"
              : "ponaj_tehnian_ore_validare_btton_valid"
          }
        />
      </div>
    </div>
  );
};

export default Pontaj;
