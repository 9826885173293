import React, { useState, useEffect } from "react";
import Locatie from "./Locatie";
import ObservatiiLocatie from "./ObservatiiLocatie";
import ModalComponent from "../components/basic/ModalComponent";
import ObservatieLocatie from "../locations/observatie/ObservatieLocatie";
import Detalii from "./Detalii";
import { isLocatieCar } from "../utils/Utils";

const TabsLocatie = ({
  currentLocation,
  activeTab,
  setCurrentLocation,
  refreshLocations,
  setTab,
  locationType,
}) => {
  const [index, setIndex] = useState(0);
  const [modalObservatie, setModalObservatie] = useState(false);

  const closeModalObservatie = () => {
    setModalObservatie(false);
  };

  const getCurrentTab = (tab) => {
    switch (tab) {
      case "locatie":
        return 0;
      case "observatii":
        return 1;
      case "detalii":
        return 2;
      default:
        return 0;
    }
  };

  const getCurrentScreen = () => {
    switch (index) {
      case 0:
        return (
          <Locatie
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            refreshLocations={refreshLocations}
            locationType={locationType}
          />
        );
      case 1:
        return <ObservatiiLocatie locatieId={currentLocation?.id} />;
      case 2:
        return (
          <Detalii
            locatieId={currentLocation?.id}
            isCar={isLocatieCar(currentLocation?.locatie)}
          />
        );
      default:
        return (
          <Locatie
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            refreshLocations={refreshLocations}
            locationType={locationType}
          />
        );
    }
  };
  useEffect(() => {
    setIndex(getCurrentTab(activeTab));
  }, [activeTab]);

  const rightClickObsTab = (e) => {
    e.preventDefault();
    if (currentLocation) setModalObservatie(true);
  };

  return (
    <>
      <div className="tabs_wrapper_locatie">
        <div
          onClick={() => setIndex(0)}
          className={
            index === 0 ? "tab_active_locatie" : "tab_not_active_locatie"
          }
        >
          <p
            className={
              index === 0
                ? "tab_text_active_locatie"
                : "tab_text_not_active_locatie"
            }
          >
            {locationType === "comenzi" ? "Furnizor" : "Locatie"}
          </p>
        </div>
        <div
          onClick={() => setIndex(2)}
          // onContextMenu={(e) => rightClickObsTab(e)}
          className={
            index === 2 ? "tab_active_locatie" : "tab_not_active_locatie"
          }
        >
          <p
            className={
              index === 2
                ? "tab_text_active_locatie"
                : "tab_text_not_active_locatie"
            }
          >
            Detalii
          </p>
        </div>
        <div
          onClick={() => setIndex(1)}
          onContextMenu={(e) => rightClickObsTab(e)}
          className={
            index === 1 ? "tab_active_locatie" : "tab_not_active_locatie"
          }
        >
          <p
            className={
              index === 1
                ? "tab_text_active_locatie"
                : "tab_text_not_active_locatie"
            }
          >
            Observatii
          </p>
        </div>
      </div>
      {getCurrentScreen()}
      <ModalComponent
        size={"md"}
        show={modalObservatie}
        close={closeModalObservatie}
        headingText={"Observatie"}
        body={
          <ObservatieLocatie
            locatieId={currentLocation && currentLocation.id}
            closeModalObservatie={closeModalObservatie}
            setTab={setTab}
          />
        }
      />
    </>
  );
};

export default TabsLocatie;
