import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { getLocationByTypeService } from "../backend/LocationService";
import LocationItem from "./LocationItem";
import ModalComponent from "../components/basic/ModalComponent";
import Location from "./Location";
import "../style/Locations.css";
import { useLocation } from "react-router-dom";
import InputComponent from "../components/basic/InputComponent";
import TabsLocatie from "../tabs_locatie/TabsLocatie";

const Locations = () => {
  const location = useLocation();
  const [locations, setLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [triggerLocations, setTriggerLocations] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();

  const [searchLocatie, setSearchLocatie] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const [searchInstalatie, setSearchInstalatie] = useState("");
  const [activeTab, setActiveTab] = useState("locatie");

  const setTab = (value) => {
    setActiveTab(value);
  };

  const getLocationText = () => {
    switch (location.state && location.state.locationType) {
      case "ascensoare":
        return "Ascensoare";
      case "administrativ":
        return "Administrativ";
      case "psi":
        return "PSI";
      case "interfoane":
        return "Interfoane";
      case "altele":
        return "Altele";
      case "sediu":
        return "Sediu/Masini";
      case "diverse":
        return "Diverse";
      case "revizii":
        return "Ascensoare";
      case "iscir":
        return "Ascensoare";
      case "smart_signage":
        return "Smart Signage";      
      case "software":
        return "Software";
      case "controllers":
        return "Controllers";
      case "personalizari_textile": 
        return "Personalizari Textile";
      case "personalizari_obiecte": 
        return "Personalizari Obiecte";
      case "tiparituri":
        return "Tiparituri";
      default:
        return "";
    }
  };

  const getLocationType = () => {
    switch (location.state && location.state.locationType) {
      case "ascensoare":
        return "ascensoare";
      case "administrativ":
        return "administrativ";
      case "notificari":
        return "notificari";
      case "interfoane":
        return "interfoane";
      case "psi":
        return "psi";
      case "altele":
        return "altele";
      case "sediu":
        return "sediu";
      case "diverse":
        return "diverse";
      case "comenzi":
        return "comenzi";
      case "revizii":
        return "ascensoare";
      case "iscir":
        return "ascensoare";
      case "smart_signage":
        return "smart_signage";
      case "software":
        return "software";
      case "controllers":
        return "controllers";
      case "personalizari_textile":
        return "personalizari_textile";
      case "personalizari_obiecte":
        return "personalizari_obiecte";
      case "tiparituri":
        return "tiparituri";
      default:
        return "ascensoare";
    }
  };

  const getLocations = async () => {
    const result = await getLocationByTypeService(getLocationType());
    result.forEach((locatie) => {
      if (locatie.id === location.state.locatieId) {
        setCurrentLocation(locatie);
      }
    });
    setLocations(result);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshLocations = () => {
    setTriggerLocations(!triggerLocations);
  };

  useEffect(() => {
    getLocations();
  }, [triggerLocations]);

  const filterLocations = () => {
    let filterActiveLocations = locations.sort((a, b) => {
      if (a.temporar !== b.temporar) {
        return a.temporar ? -1 : 1; // Sort by "temporar" (true before false)
      }
      return b.activ - a.activ; // Sort by "activ" if "temporar" values are equal
    });
    let filterLocationsByLocation = filterActiveLocations.filter(
      (location) =>
        location.locatie &&
        location.locatie.toLowerCase().indexOf(searchLocatie.toLowerCase()) !==
          -1
    );

    let filterLocationsByClient = filterLocationsByLocation.filter(
      (location) => {
        if (searchClient) {
          return (
            location.client &&
            location.client
              .toLowerCase()
              .indexOf(searchClient.toLowerCase()) !== -1
          );
        } else {
          return filterLocationsByLocation;
        }
      }
    );

    return filterLocationsByClient.filter((location) => {
      // return (
      //   location.descriere != null &&
      //   location.descriere
      //     .toLowerCase()
      //     .indexOf(searchInstalatie.toLowerCase()) !== -1
      // );
      if (searchInstalatie) {
        return (
          location.descriere &&
          location.descriere
            .toLowerCase()
            .indexOf(searchInstalatie.toLowerCase()) !== -1
        );
      } else {
        return filterLocationsByLocation;
      }
    });
  };

  const clearFilter = () => {
    setSearchClient("");
    setSearchInstalatie("");
    setSearchLocatie("");
  };

  const getBackgroundColorLocatie = () => {
    switch (location.state && location.state.locationType) {
      case "interventii":
        return "backgroudLocatieInterventii";
      case "ascensoare":
        return "backgroudLocatieAscensoare";
      case "revizii":
        return "backgroudLocatieAscensoare";
      case "iscir":
        return "backgroudLocatieAscensoare";
      case "interfoane":
        return "backgroudLocatieInterfoane";
      case "psi":
        return "backgroudLocatiePsi";
      case "altele":
        return "backgroudLocatieAltele";
      case "sediu":
        return "backgroudLocatieSediuMasini";
      case "diverse":
        return "backgroudLocatieDiverse";
      default:
        return "backgroundLocatieDefault";
    }
  };

  return (
    <Row>
      <Col md={9}>
        <Row className={"mb-2"}>
          <div className="headerWrapper_location">
            <div
              className={`locations_add ${getBackgroundColorLocatie()}`}
              onClick={() => setShowModal(true)}
            >
              {location.state && location.state.locationType === "comenzi" ? (
                <h2>Furnizori</h2>
              ) : (
                <h2>{`Locatii ${getLocationText()}`}</h2>
              )}
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <ModalComponent
              show={showModal}
              size="lg"
              close={() => setShowModal(false)}
              headingText={
                location.state && location.state.locationType === "comenzi"
                  ? "Adauga Furnizor"
                  : "Adauga Locatie"
              }
              body={
                <Location
                  closeModal={closeModal}
                  refreshLocations={refreshLocations}
                  locationType={location.state && location.state.locationType}
                  locations={locations}
                />
              }
            />
            <Row>
              <Col>
                <Row>
                  <Col md={2}>
                    <InputComponent
                      type="text"
                      placeholder={
                        location.state &&
                        location.state.locationType === "comenzi"
                          ? "Furnizor"
                          : "Locatie"
                      }
                      value={searchLocatie}
                      onChange={(e) => setSearchLocatie(e.target.value)}
                    />
                  </Col>
                  {location.state &&
                    location.state.locationType !== "comenzi" &&
                    location.state.locationType !== "sediu" && (
                      <Col md={3}>
                        <InputComponent
                          // className="ml-2"
                          type="text"
                          placeholder="Client"
                          value={searchClient}
                          onChange={(e) => setSearchClient(e.target.value)}
                        />
                      </Col>
                    )}
                  {location.state && location.state.locationType !== "comenzi" && (
                    <Col
                      md={location.state.locationType !== "sediu" ? 7 : 10}
                      className="pl-0 pr-0"
                    >
                      <div
                        className="d-inline-block mr-2"
                        style={{ width: "90%" }}
                      >
                        <InputComponent
                          type="text"
                          placeholder="Instalatie"
                          value={searchInstalatie}
                          onChange={(e) => setSearchInstalatie(e.target.value)}
                        />
                      </div>
                      <div
                        className="closeModalButton d-inline-block"
                        onClick={clearFilter}
                      >
                        <i
                          style={{ color: "#c60800" }}
                          className="far fa-times-circle fa-lg"
                        ></i>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="middleScreenLocations mt-1">
              <Col>
                {filterLocations().map((location, index) => (
                  <LocationItem
                    key={index}
                    location={location}
                    refreshLocations={refreshLocations}
                    setCurrentLocation={setCurrentLocation}
                    setTab={setTab}
                    getBackgroundColorLocatie={getBackgroundColorLocatie}
                    currentLocation={currentLocation}
                    locations={locations}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLocatie
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          refreshLocations={refreshLocations}
          locationType={location.state && location.state.locationType}
          activeTab={activeTab}
          setTab={setTab}
        />
      </Col>
    </Row>
  );
};

export default Locations;
