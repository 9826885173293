import React, { useState, useEffect } from "react";
import {
  getPontajeDispecerService,
  getRezumatDispecerService,
} from "../backend/PontajeService";
import PontajItem from "./PontajItem";

const Validare = () => {
  const [pontaje, setPontaje] = useState([]);
  const [rezumatDispecer, setRezumatDispecer] = useState();
  const [triggerPontaje, setTriggerPontaje] = useState(false);

  const getPontajeDispecer = async () => {
    try {
      const result = await getPontajeDispecerService();
      setPontaje(result);
    } catch (err) {
      console.error(err);
    }
  };

  const getRezumatDispecer = async () => {
    try {
      const result = await getRezumatDispecerService();
      setRezumatDispecer(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPontajeDispecer();
    getRezumatDispecer();
  }, [triggerPontaje]);

  const refreshPontaje = () => {
    setTriggerPontaje(!triggerPontaje);
  };

  return (
    <div>
      <div className="pontaj_rezumat_first_line_wrapper">
        <div>
          Total Avans:{" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            rezumatDispecer && rezumatDispecer.avans
              ? rezumatDispecer && rezumatDispecer.avans
              : 0
          )}
        </div>
        <div>
          Total Plata:{" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            rezumatDispecer && rezumatDispecer.plata
              ? rezumatDispecer && rezumatDispecer.plata
              : 0
          )}
        </div>
      </div>
      <div className="tab_pontaje_validare">
        {pontaje.map((pontaj, index) => (
          <PontajItem
            key={index}
            pontaj={pontaj}
            refreshPontaje={refreshPontaje}
          />
        ))}
      </div>
    </div>
  );
};

export default Validare;
