import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  getLocationByTypeService,
  getLocationsService,
} from "../backend/LocationService";
import ButtonComponent from "../components/basic/ButtonComponent";
import Select from "react-select";
import { updateLucrareDomeniuService } from "../backend/LucrareService";

const MutaLucrare = ({
  lucrare,
  setLucrare,
  closeModal,
  closeModalLucrare,
  refreshLucrari,
}) => {
  const [domenii, setDomenii] = useState([
    {
      value: "interventii",
      label: "Interventii",
    },
    {
      value: "ascensoare",
      label: "Ascensoare",
    },
    {
      value: "interfoane",
      label: "Interfoane",
    },
    {
      value: "psi",
      label: "PSI",
    },
    {
      value: "altele",
      label: "Altele",
    },
    {
      value: "sediu",
      label: "Sediu/Masini",
    },
    {
      value: "diverse",
      label: "Diverse",
    },
  ]);
  const [locatii, setLocatii] = useState([]);

  const [selectedDomeniu, setSelectedDomeniu] = useState(null);
  const [selectedLocatie, setSelectedLocatie] = useState(null);
  const [error, setError] = useState("");
  const [isInterventie, setIsInterventie] = useState(lucrare?.isInterventie);
  const [blocking, setBlocking] = useState(false);

  const getLocatii = async () => {
    try {
      const result =
        !selectedDomeniu || selectedDomeniu?.value === "interventii"
          ? await getLocationsService()
          : await getLocationByTypeService(selectedDomeniu?.value);
      setLocatii(
        result.map((locatie) => {
          return {
            value: locatie?.id,
            label: locatie?.locatie,
            type: locatie?.type,
            temporar: locatie?.temporar,
          };
        })
      );
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getLocatii();

    if (selectedDomeniu?.value === "interventii") {
      setTimeout(() => {
        setSelectedLocatie({
          value: lucrare?.locatie?.value,
          label: lucrare?.locatie?.label,
          temporar: lucrare?.locatie?.temporar,
          type: lucrare?.locatie?.type,
        });
      }, 100);
    }
  }, [selectedDomeniu]);

  useEffect(() => {
    if (selectedDomeniu?.value === "interventii" || lucrare?.isInterventie) {
      setTimeout(() => {
        setSelectedLocatie({
          value: lucrare?.locatie?.value,
          label: lucrare?.locatie?.label,
          temporar: lucrare?.locatie?.temporar,
          type: lucrare?.locatie?.type,
        });
      }, 100);
    }
  }, []);

  const mutaLucrare = async () => {
    try {
      setBlocking(true);
      if (selectedDomeniu) {
        if (selectedLocatie) {
          const domeniu =
            selectedDomeniu?.value === "interventii"
              ? lucrare?.lucrareType
              : selectedDomeniu?.value;

          await updateLucrareDomeniuService(
            lucrare?.uid,
            selectedLocatie?.value,
            domeniu,
            isInterventie
          );
          refreshLucrari();
          closeModal();
          closeModalLucrare();
        } else {
          setError("locatie");
        }
      } else {
        setError("domeniu");
      }
    } catch (err) {
    } finally {
      setBlocking(false);
    }
  };
  const getBackgroundColorLocatie = (type) => {
    switch (type) {
      case "interventii":
        return "#fdcaa6";
      case "ascensoare":
        return "#9ec1e0";
      case "interfoane":
        return "#a6d8b9";
      case "psi":
        return "#f1b2ac";
      case "altele":
        return "#ffcc66";
      case "sediu":
        return "#ffe7b3";
      case "diverse":
        return "#ffe7b3";
      default:
        return "#fffff5";
    }
  };

  const customStylesDomenii = {
    singleValue: (provided, state) => {
      const color = "black";

      return { color };
    },
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.value),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        backgroundColor: "#6c757d",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };

  const customStylesLocatii = {
    singleValue: (provided, state) => {
      const color = "black";

      return { color };
    },
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.type),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        backgroundColor: "#6c757d",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className={error === "domeniu" ? "locatieError w-100" : ""}>
            <Select
              options={domenii}
              styles={customStylesDomenii}
              placeholder={"Domeniii"}
              formatCreateLabel={() => null || undefined}
              noOptionsMessage={() => null}
              onChange={(value) => {
                setSelectedDomeniu(value);
                setError("");
                setSelectedLocatie(null);
                if (value?.value === "interventii") {
                  setIsInterventie(true);
                } else {
                  setIsInterventie(false);
                }
              }}
              value={selectedDomeniu}
            />
          </div>
        </Col>
        <Col className="text-center">
          <div className={error === "locatie" ? "locatieError w-100" : ""}>
            <Select
              options={locatii}
              styles={customStylesLocatii}
              placeholder={"Locatii"}
              formatCreateLabel={() => null || undefined}
              noOptionsMessage={() => null}
              onChange={(value) => {
                setSelectedLocatie(value);
                setError("");
              }}
              value={selectedLocatie}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="text-center">
          <ButtonComponent
            text={"Muta"}
            onClick={mutaLucrare}
            variant={"warning"}
            disabled={blocking}
          />
        </Col>
      </Row>
    </>
  );
};

export default MutaLucrare;
