import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonComponent from "../components/basic/ButtonComponent";
import { changeFazaLucrareService } from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";

const FinalizareLucrareComenzi = ({
  lucrare,
  closeModalFinalizare,
  setTab,
  refreshLucrari
}) => {
  const [input1, setInput1] = useState(false);
  const [input2, setInput2] = useState(false);
  const { refreshNumere, blocking, setBlocking } = useContext(RefreshContext);
  
  const finalizareLucrare = async () => {
    try {
      setBlocking(true)
      if (input1 && input2) {
        await changeFazaLucrareService(lucrare.uid, 8);
        refreshLucrari();
        refreshNumere();
        setTab("detalii");
        closeModalFinalizare();
      }
    } catch (err) {
      console.error(err);
    }
    finally{
      setBlocking(false)
    }
  };

  return (
    <>
      <Row className="text-center">
        <Col className="text-center">
          <input
            className="d-inline-block mr-2"
            type="checkbox"
            checked={input1}
            onChange={() => setInput1(!input1)}
          />
          <p className="d-inline-block">Corespunde cu factura</p>
        </Col>
        <Col className="text-center">
          <input
            className="d-inline-block mr-2"
            type="checkbox"
            checked={input2}
            onChange={() => setInput2(!input2)}
          />
          <p className="d-inline-block">Factura Corecta</p>
        </Col>
      </Row>
      <Row className="text-center mt-3">
        <Col>
          <ButtonComponent
            color="button_finalizare_lucrare"
            text="Finalizare"
            onClick={finalizareLucrare}
            setTab={setTab}
            disabled={blocking}
          />
        </Col>
      </Row>
    </>
  );
};

export default FinalizareLucrareComenzi;
