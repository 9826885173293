import React, { useState, useEffect } from "react";
import ModalComponent from "./ModalComponent";
import ButtonComponent from "./ButtonComponent";
import { Row, Col } from "react-bootstrap";

const ConfirmActionComponent = ({
  buttonIsPressed = false,
  yesPressAction,
  yesButtontext = "Da",
  noPressAction,
  text,
  actionTitle,
  content,
  showButtons = false
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (buttonIsPressed) setShowModal(true);
  }, [buttonIsPressed]);

  return (
    <ModalComponent
      show={showModal}
      headingText={actionTitle}
      close={() => {
        //set button delete state to false in order to reopen modal when click again on delete button
        noPressAction();
        setShowModal(false);
      }}
      body={
        <>
          <div>{text}</div>
          {content && content}
          {!showButtons && (
            <Row className="mt-3">
              <Col>
                <ButtonComponent
                  text={"Nu"}
                  disabled={showButtons}
                  onClick={() => {
                    //set button delete state to false in order to reopen modal when click again on delete button
                    noPressAction();
                    //close modal
                    setShowModal(false);
                  }}
                />
              </Col>
              <Col className="text-right">
                <ButtonComponent
                  text={yesButtontext}
                  disabled={showButtons}
                  onClick={() => {
                    //delete item
                    yesPressAction();
                    //set button delete state to false in order to reopen modal when click again on delete button
                    noPressAction();
                    //close modal
                    setShowModal(false);
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};

export default ConfirmActionComponent;
