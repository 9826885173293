import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import RightComponentWrapper from "../components/basic/RightComponentWrapper";
import {
  getLocationByTypeService,
  getLocationsService,
} from "../backend/LocationService";
import Select from "react-select";
import { database } from "../firebase";
import { UserContext } from "../context/UserContext";
import { mapMonth, isLocatieCar, showDateFormatFullYear } from "../utils/Utils";
import { RefreshContext } from "../context/RefreshContext";
import { ref, get } from 'firebase/database';
import attach_docs from "../imgs/attach.png";
import folder from "../imgs/folder.png";
import { uploadDocument } from "../backend/FileUploadService";
import ModalComponent from "../components/basic/ModalComponent";

const Locatie = ({ currentLucrare, locationType, locatieName, locatieId }) => {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const { isAdmin, isDispecer, user } = useContext(UserContext);
  const hiddenDocumentInput = useRef(null);
  const { setIstoricLocationId, refreshLocatieDocuments } = useContext(RefreshContext);
  const [error, setError] = useState();
  const getBackgroundColorLocatie = (type) => {
    switch (type) {
      case "interventii":
        return "#b0b0b0";
      case "ascensoare":
        return "#9ec1e0";
      case "interfoane":
        return "#a6d8b9";
      case "psi":
        return "#f1b2ac";
      case "altele":
        return "#ffcc66";
      case "sediu":
        return "#ffe7b3";
      case "diverse":
        return "#ffe7b3";
      default:
        return "#fffff5";
    }
  };

  const customStyles = {
    singleValue: (provided, state) => {
      const color = "white";

      return { color };
    },
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.type),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        backgroundColor: "#6c757d",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };

  const getLocations = async () => {
    try {
      let result = [];
      switch (locationType) {
        case "administrativ": {
          result = await getLocationsService();
          break;
        }
        case "notificari": {
          result = await getLocationsService();
          break;
        }
        case "diverse": {
          result = await getLocationsService();
          break;
        }
        case "oferte": {
          let rrr = await getLocationsService();
          result = rrr.filter(
            (location) =>
              location.type === "ascensoare" ||
              location.type === "interfoane" ||
              location.type === "psi" ||
              location.type === "altele"
          );
          break;
        }
        case "ascensoare": {
          result = await getLocationByTypeService("ascensoare");
          break;
        }
        case "revizii": {
          result = await getLocationByTypeService("ascensoare");
          break;
        }
        case "iscir": {
          result = await getLocationByTypeService("ascensoare");
          break;
        }
        case "psi": {
          result = await getLocationByTypeService("psi");
          break;
        }
        case "interfoane": {
          result = await getLocationByTypeService("interfoane");
          break;
        }
        case "altele": {
          result = await getLocationByTypeService("altele");
          break;
        }
        case "sediu": {
          result = await getLocationByTypeService("sediu");
          break;
        }
        case "comenzi": {
          result = await getLocationByTypeService("comenzi");
          break;
        }
        case "smart_signage": {
          result = await getLocationByTypeService("smart_signage");
          break;
        }
        case "software": {
          result = await getLocationByTypeService("software");
          break;
        }
        case "controllers": {
          result = await getLocationByTypeService("controllers");
          break;
        }
        case "personalizari_textile": {
          result = await getLocationByTypeService("personalizari_textile");
          break;
        }
        case "personalizari_obiecte": {
          result = await getLocationByTypeService("personalizari_obiecte");
          break;
        }
        case "tiparituri": {
          result = await getLocationByTypeService("tiparituri");
          break;
        }
        default:
          result = await getLocationsService();
      }
      setLocations(
        result.map((locatie) => {
          return {
            label: locatie.locatie,
            value: locatie.id,
            temporar: locatie.temporar,
            type: locatie.type,
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const addNrTelefon = () => {
    if (isAdmin() || isDispecer()) {
      const itemsRef = ref(database, "logics");
      get(itemsRef).then(snapshot => {
        let items = snapshot.val();
        if (items === null) {
          if (currentLucrare && currentLucrare.locatieDTO.tel) {
            itemsRef.push({
              userName: user.userRoleDTO.userName,
              nr: currentLucrare.locatieDTO.tel,
            });
          }
        } else {
          for (let item in items) {
            if (items[item].userName === user.userRoleDTO.userName) {
              return;
            } else {
              if (currentLucrare && currentLucrare.locatieDTO.tel) {
                itemsRef.push({
                  userName: user.userRoleDTO.userName,
                  nr: currentLucrare.locatieDTO.tel,
                });
              }
            }
          }
        }
      });
    }
  };

  const onSelectIstoricLocation = (value) => {
    setIstoricLocationId(value.value);
    history.push("/istoricLocatie", {
      locatieId: value.value,
      locationType: locationType,
      locatieName: value.label,
    });
  };

  const chooseDocumentToUpload = event => {
    hiddenDocumentInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleUploadDocument = async event => {
    try {
      const fileToUpload = event.target.files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload);
      if (currentLucrare?.locatieDTO) {
         await uploadDocument(formData, currentLucrare.locatieDTO.type, `${currentLucrare.locatieDTO.locatie}_${currentLucrare.locatieDTO.id}`)
      }
      else if (locatieName && locatieId && locationType) {
         await uploadDocument(formData, locationType, `${locatieName}_${locatieId}`)
      }
      refreshLocatieDocuments();
      event.target.value = ''; // Resetting the value to an empty string clears the file selection
    } catch (err) {
      setError("Eroare la atasarea documentelor.");
    }
  }

  return (
    <div>
      <Row className="locatie_lucrare_wrapper_big">
        <Col className="text-center">
          <div className="locatie_istoric_and_gallery_wrapper">
            <div className="locatie_istoric_select_locatie">
              <div className="locatie_istoric_select_item">
                <Select
                  options={locations}
                  styles={customStyles}
                  value={null}
                  placeholder={
                    locatieName
                      ? locatieName
                      : currentLucrare?.locatieDTO?.locatie
                        ? currentLucrare?.locatieDTO?.locatie
                        : locationType === "comenzi"
                          ? "Furnizor"
                          : "Locatie"
                  }
                  formatCreateLabel={() => null || undefined}
                  noOptionsMessage={() => null}
                  onChange={(value) => {
                    onSelectIstoricLocation(value);
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="locatie_lucrare_wrapper_big">
        <Col className="text-center">
          <div className="locatie_istoric_and_gallery_wrapper">
            <div className="rightComponentWrapperButtons">
              {(currentLucrare || (locatieName && locatieId)) && <div
                className="d-inline-block mr-2 locatie_istoric_and_gallery_button"
                onClick={() => {

                  if (currentLucrare?.locatieDTO) {
                    setIstoricLocationId(currentLucrare.locatieDTO.id);
                    history.push("/istoricLocatie", {
                      locatieId: currentLucrare.locatieDTO.id,
                      locatieName: currentLucrare.locatieDTO.locatie,
                      locationType: locationType,
                    });
                  }
                  else if (locatieName && locatieId && locationType) {
                    setIstoricLocationId(locatieId);
                    history.push("/istoricLocatie", {
                      locatieId: locatieId,
                      locatieName: locatieName,
                      locationType: locationType,
                    });
                  } else if (locationType === "administrativ") {
                    setIstoricLocationId(locatieId);
                    history.push("/istoricLocatie", {
                      locationType: locationType,
                      locatieName: locatieName,
                      locatieId: locatieId,
                      currentLucrare: null
                    });
                  }
                  }}
              >
                <p className="rightComponentLabel">Istoric</p>
              </div>}
              {(currentLucrare || (locatieName && locatieId)) && <div
                className="d-inline-block mr-2 locatie_istoric_and_gallery_button"
                onClick={() => {
                  if (currentLucrare?.locatieDTO) {
                    setIstoricLocationId(currentLucrare.locatieDTO.id);
                    history.push("/folderLocatie", {
                      locationType: currentLucrare.locatieDTO.type,
                      locatieName: currentLucrare.locatieDTO.locatie,
                      locatieId: currentLucrare.locatieDTO.id,
                      currentLucrare: currentLucrare
                    });
                  }
                  else if (locatieName && locatieId && locationType) {
                    setIstoricLocationId(locatieId);
                    history.push("/folderLocatie", {
                      locationType: locationType,
                      locatieName: locatieName,
                      locatieId: locatieId,
                      currentLucrare: null
                    });
                  }
                }}
              >
                <img src="gallery.png" alt="photos" style={{ maxHeight: "100%" }} />
              </div>}
              {(currentLucrare || (locatieName && locatieId)) && <div
                className="d-inline-block mr-2 locatie_istoric_and_gallery_button"
                onClick={chooseDocumentToUpload}
              >
                <input 
                type="file" 
                style={{display: 'none'}}
                onChange={handleUploadDocument}
                ref={hiddenDocumentInput}
                />
                <img src={attach_docs} alt="photos" style={{ maxHeight: "100%" }} />
              </div>}
              {(currentLucrare || (locatieName && locatieId)) && <div
                className="d-inline-block mr-2 locatie_istoric_and_gallery_button"
                onClick={() => {
                  if (currentLucrare?.locatieDTO) {
                    history.push("/documenteLocatieViewer", {
                      locationType: currentLucrare.locatieDTO.type,
                      locatieName: currentLucrare.locatieDTO.locatie,
                      locatieId: currentLucrare.locatieDTO.id,
                      currentLucrare: currentLucrare
                    });
                  }
                  else if (locatieName && locatieId && locationType) {
                    history.push("/documenteLocatieViewer", {
                      locationType: locationType,
                      locatieName: locatieName,
                      locatieId: locatieId,
                      currentLucrare: null
                    });
                  }
                }}
              >
                <img src={folder} alt="photos" style={{ maxHeight: "100%" }} />
              </div>}
            </div>
          </div>
        </Col>
      </Row>

      <>{error && (
        <ModalComponent
            size={error ? "sm" : "md"}
            show={error !== null}
            close={() => setError()}
            body={<h3 style={{color: "red"}}>{error}</h3>}
          />      
      )}</>
      {currentLucrare?.locatieDTO && (
        <>
          {currentLucrare?.locatieDTO?.locatie && (
            <RightComponentWrapper
              label={locationType === "comenzi" ? "Furnizor" : "Locatie"}
              value={currentLucrare?.locatieDTO?.locatie}
            />
          )}
          {currentLucrare?.locatieDTO?.client &&
            locationType !== "comenzi" &&
            locationType !== "sediu" && (
              <RightComponentWrapper
                label={"Client"}
                value={currentLucrare?.locatieDTO?.client}
              />
            )}
          {currentLucrare?.locatieDTO?.instSt && (
            <RightComponentWrapper
              label={"Stingatoare"}
              value={`${currentLucrare?.terSt
                  ? `S: ${mapMonth(
                    currentLucrare?.locatieDTO?.terSt
                  )} \u00A0\u00A0 `
                  : ``
                } ${currentLucrare?.locatieDTO
                  ? currentLucrare?.locatieDTO?.instSt
                  : ""
                }`}
            />
          )}
          {currentLucrare?.locatieDTO && locationType === "psi" ? (
            <>
              {currentLucrare?.locatieDTO?.instHid && (
                <RightComponentWrapper
                  label={"Hidranti"}
                  value={`${currentLucrare?.locatieDTO?.terHid
                      ? `S: ${mapMonth(
                        currentLucrare?.locatieDTO?.terHid
                      )} \u00A0\u00A0\ `
                      : ``
                    } ${currentLucrare?.locatieDTO?.instHid
                      ? currentLucrare?.locatieDTO?.instHid
                      : ""
                    }`}
                />
              )}
            </>
          ) : (
            <>
              {currentLucrare?.locatieDTO?.descriere && (
                <RightComponentWrapper
                  label={"Instalatie"}
                  value={currentLucrare?.locatieDTO?.descriere}
                />
              )}
            </>
          )}
          {currentLucrare &&
            (currentLucrare.lucrareType === "iscir" ||
              currentLucrare.lucrareType === "ascensoare" ||
              currentLucrare.lucrareType === "revizii") && (
              <RightComponentWrapper
                label={"Scadenta"}
                value={
                  currentLucrare?.locatieDTO?.terIscir
                    ? `${mapMonth(
                      new Date(
                        currentLucrare?.locatieDTO?.terIscir
                      ).getMonth() + 1
                    )} ${new Date(
                      currentLucrare?.locatieDTO?.terIscir
                    ).getFullYear()}`
                    : `Fara Scadenta`
                }
              />
            )}
          {currentLucrare.lucrareType === "sediu" &&
            isLocatieCar(currentLucrare?.locatieDTO?.locatie) && (
              <>
                <RightComponentWrapper
                  label={"ITP"}
                  value={
                    currentLucrare?.locatieDTO?.itp
                      ? showDateFormatFullYear(currentLucrare?.locatieDTO?.itp)
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"Parcare"}
                  value={
                    currentLucrare?.locatieDTO?.parcare
                      ? showDateFormatFullYear(currentLucrare?.locatieDTO?.parcare)
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"RCA"}
                  value={
                    currentLucrare?.locatieDTO?.rca
                      ? showDateFormatFullYear(currentLucrare?.locatieDTO?.rca)
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"Rovigneta"}
                  value={
                    currentLucrare?.locatieDTO?.rovigneta
                      ? showDateFormatFullYear(
                        currentLucrare?.locatieDTO?.rovigneta
                      )
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"Km pana la revizie"}
                  value={
                    currentLucrare?.locatieDTO?.kmRev &&
                      currentLucrare?.locatieDTO?.km
                      ? currentLucrare?.locatieDTO?.kmRev +
                      10000 -
                      currentLucrare?.locatieDTO?.km
                      : ""
                  }
                />
              </>
            )}
          {locationType === "psi" && (
            <RightComponentWrapper
              label={"Zona"}
              value={currentLucrare?.locatieDTO?.zona}
            />
          )}
          {currentLucrare?.locatieDTO?.adresa &&
            locationType !== "sediu" && (
              <RightComponentWrapper
                label={"Adresa"}
                value={currentLucrare?.locatieDTO?.adresa}
              />
            )}
          {currentLucrare?.locatieDTO?.persoana && locationType !== "sediu" && (
            <RightComponentWrapper
              label={"Persoana"}
              value={currentLucrare?.locatieDTO?.persoana}
            />
          )}
          {currentLucrare?.locatieDTO?.tel && locationType !== "sediu" && (
            <div onClick={() => addNrTelefon()}>
              <RightComponentWrapper
                showBlue
                label={"Telefon"}
                value={currentLucrare?.locatieDTO?.tel}
              />
            </div>
          )}
          {currentLucrare?.locatieDTO?.email && locationType !== "sediu" && (
            <RightComponentWrapper
              label={"E-Mail"}
              value={currentLucrare?.locatieDTO?.email}
            />
          )}
          {currentLucrare?.locatieDTO?.contract &&
            locationType !== "comenzi" &&
            locationType !== "sediu" && (
              <RightComponentWrapper
                label={"Contract"}
                value={currentLucrare?.locatieDTO?.contract}
              />
            )}

          {currentLucrare?.locatieDTO?.cui &&
            locationType !== "comenzi" &&
            locationType !== "sediu" && (
              <RightComponentWrapper
                label={"CUI"}
                value={currentLucrare?.locatieDTO?.cui}
              />
            )}
          {currentLucrare?.locatieDTO?.firma &&
            locationType === "ascensoare" && (
              <RightComponentWrapper
                label={"Firma"}
                value={currentLucrare?.locatieDTO?.firma}
              />
            )}
        </>
      )}
    </div>
  );
};

export default Locatie;
