import React, { useState, useEffect } from "react";
import LocatieLucrareItem from "../locations/detalii/LocatieLucrareItem";
import { getLucrariLocatieService } from "../backend/LocationService";
const Detalii = ({ locatieId, isCar }) => {
  const [items, setItems] = useState([]);

  const getLucrari = async () => {
    try {
      const result = await getLucrariLocatieService(locatieId);
      setItems(result);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getLucrari();
  }, [locatieId]);

  return (
    <>
      {isCar && (
        <div className="mt-2 observatii_wrapper">
          {items.length > 0 ? (
            <>
              {items.map((item, index) => (
                <LocatieLucrareItem key={index} item={item} />
              ))}
            </>
          ) : (
            <div className="noObservatii">Nu exista alimentari</div>
          )}
        </div>
      )}
    </>
  );
};

export default Detalii;
