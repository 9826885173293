import { cars } from "./Values";

export const getMonthWithTwoDigits = (date) => {
  let month = date.getMonth() + 1;
  return month < 10 ? "0" + month : "" + month;
};

export const getDayWithTwoDigits = (date) => {
  let day = date.getDate();
  return day < 10 ? "0" + day : "" + day;
};

export const getHourWithTwoDigits = (date) => {
  let hour = date.getHours();
  return hour < 10 ? "0" + hour : "" + hour;
};

export const getMinuteWithTwoDigits = (date) => {
  let minute = date.getMinutes();
  return minute < 10 ? "0" + minute : "" + minute;
};

export const showDateFormat = (date) => {
  let d = new Date(date);
  return `${getDayWithTwoDigits(d)}.${getMonthWithTwoDigits(d)}.${d
    .getFullYear()
    .toString()}`;
};

export const showDateFormatWithHyphens = (date) => {
  return `${date.getFullYear()}-${getMonthWithTwoDigits(
    date
  )}-${getDayWithTwoDigits(date)}`;
};

export const showTimeFormat = (date) => {
  if (date.getHours() !== 0 || date.getMinutes() !== 0) {
    return `${getHourWithTwoDigits(date)}:${getMinuteWithTwoDigits(date)}`;
  } else {
    return "";
  }
};

export const getRomanianDay = (date) => {
  let day = date.getDay();

  switch (day) {
    case 0:
      return "Duminica";
    case 1:
      return "Luni";
    case 2:
      return "Marti";
    case 3:
      return "Miercuri";
    case 4:
      return "Joi";
    case 5:
      return "Vineri";
    case 6:
      return "Sambata";
    default:
      return "Luni";
  }
};

export const getCurrentMonthWithThreeCharacters = () => {
  const monthNames = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];

  let date = new Date();
  let luna = date.getMonth() - 1;
  if (luna < 0) luna = 11;
  return monthNames[luna].substring(0, 3);
};

export const checkDatesEquals = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const convertDateToFullYear = (inputDate) => {
  const [day, month, year] = inputDate.split('.');

  if (day && month && year) {
    // Convert two-digit year to four-digit year by prefixing with "20"
    const fullYear = year.length === 2 ? `20${year}` : year;
    return`${day}.${month}.${fullYear}`;
  } else {
    return inputDate;
  }
};

export const showDateFormatFullYear = (date) => {
  let d = new Date(date);
  return `${getDayWithTwoDigits(d)}.${getMonthWithTwoDigits(d)}.${d
    .getFullYear()
    .toString()}`;
};

export const showDateFormatFullYearXX = (date) => {
  let d = new Date(date);
  let result = `${getDayWithTwoDigits(d)}.${getMonthWithTwoDigits(d)}.${d
    .getFullYear()
    .toString()}`;
    return result.slice(0, 8) + "XX";
};

export const arraysMatch = (arr1, arr2) => {
  if (!arr1 || !arr2) return false;

  let arr1Sort = arr1.sort();
  let arr2Sort = arr2.sort();

  // Check if the arrays are the same length
  if (arr1Sort.length !== arr2Sort.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1Sort.length; i++) {
    if (arr1Sort[i] !== arr2Sort[i]) return false;
  }

  // Otherwise, return true
  return true;
};
export const mapMonth = (month) => {
  switch (month) {
    case 1:
      return "Ian";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "Mai";
    case 6:
      return "Iun";
    case 7:
      return "Iul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Noi";
    case 12:
      return "Dec";
    default:
      return "";
  }
};

export const getCurrentMonthIndex = () => {
  let date = new Date();
  let luna = date.getMonth() + 1;

  return luna;
};

export const isLocatieCar = (locatie) => {
  return cars.includes(locatie);
};
