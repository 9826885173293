import React from "react";
import "../../style/RightComponentWrapper.css";
const RightComponentWrapper = ({ label, value, showRed, showBlue }) => {
  return (
    <div className="rightComponentWrapper">
      <p
        className={
          showRed
            ? `rightComponentLabelRed d-inline-block mr-2`
            : `rightComponentLabel d-inline-block mr-2`
        }
      >
        {label}
      </p>
      <div
        className={
          showBlue
            ? "nr_telefon rightComponentValue d-inline-block"
            : "rightComponentValue d-inline-block"
        }
      >
        {value}
      </div>
    </div>
  );
};

export default RightComponentWrapper;
