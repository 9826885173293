import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import {
  getLocationByTypeService,
  addLocationService,
} from "../backend/LocationService";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
  changeFazaLucrareService,
  updateLucrareService,
} from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";

const ComandaModal = ({
  closeModalComanda,
  currentLucrare,
  refreshLucrari,
  closeModal,
}) => {
  const [isRed, setIsRed] = useState(false);
  const [error, setError] = useState("");
  const { refreshNumere, blocking, setBlocking } = useContext(RefreshContext);
  const [lucrare, setLucrare] = useState();
  const [locations, setLocation] = useState([]);

  const clearError = () => {
    setTimeout(() => {
      setError("");
      if (!(lucrare && lucrare.locatie && lucrare.locatie.value)) {
        setLucrare({ ...lucrare, locatie: null });
      } else {
        setLucrare({
          ...lucrare,
          locatie: currentLucrare.locatieDTO
            ? {
                value: currentLucrare.locatieDTO.id,
                label: currentLucrare.locatieDTO.locatie,
                temporar: currentLucrare.locatieDTO.temporar,
              }
            : null,
        });
      }
    }, 4000);
  };

  const getLocations = async () => {
    try {
      const result = await getLocationByTypeService("comenzi");

      setLocation(
        result.map((location) => {
          return {
            value: location.id,
            label: location.locatie,
            temporar: location.temporar,
            type: location.type,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLucrare({
      ...lucrare,
      descriere: currentLucrare.descriere,
      uid: currentLucrare.uid,
      locatie: currentLucrare.locatieDTO
        ? {
            value: currentLucrare.locatieDTO.id,
            label: currentLucrare.locatieDTO.locatie,
            temporar: currentLucrare.locatieDTO.temporar,
          }
        : null,
    });
    getLocations();
  }, []);

  const getBackgroundColorLocatie = (type) => {
    switch (type) {
      case "interventii":
        return "#b0b0b0";
      case "ascensoare":
        return "#9ec1e0";
      case "interfoane":
        return "#a6d8b9";
      case "psi":
        return "#f1b2ac";
      case "altele":
        return "#ffcc66";
      case "sediu":
        return "#ffe7b3";
      case "diverse":
        return "#ffe7b3";
      default:
        return "#fffff5";
    }
  };

  const customStyles = {
    singleValue: (provided, state) => {
      const color =
        isNaN(lucrare.locatie.value) || lucrare.locatie.temporar || isRed
          ? "#c60800"
          : "black";

      return { color };
    },
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row"
    }),
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.type),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };
  const isLocationInDatabase = () => {
    const filterLocations = locations.filter(
      (l) => l.label.toLowerCase() === lucrare.locatie.value.toLowerCase()
    );

    return filterLocations.length > 0;
  };

  const comandat = async () => {
    try {
      setBlocking(true);
      if (lucrare && lucrare.locatie) {
        if (isNaN(lucrare.locatie.value)) {
          if (!isLocationInDatabase()) {
            const result = await addLocationService({
              locatie: lucrare.locatie.value,
              type: "comenzi",
              temporar: true,
            });

            await updateLucrareService(lucrare, result.id);
            await changeFazaLucrareService(currentLucrare.uid, 6);
          } else {
            setError("locatie_already_exists");
            clearError();
            return;
          }
        } else {
          await updateLucrareService(lucrare, lucrare?.locatie?.value);

          await changeFazaLucrareService(currentLucrare.uid, 6);
        }
        closeModalComanda();
        closeModal();

        refreshLucrari();
        refreshNumere();
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  return (
    <>
      {error === "locatie_already_exists" && (
        <div className="location_already_exists">
          <h3>Exista furnizorul in baza de date!</h3>
        </div>
      )}
      <Row>
        <Col>
          <div
            className={error === "locatie" ? "locatieError" : "lucrareLocatie"}
          >
            <CreatableSelect
              className={isRed ? "xxx" : "yyy"}
              onBlur={(e) => {
                if (!(lucrare && lucrare.locatie)) {
                  setLucrare({
                    ...lucrare,
                    locatie: {
                      value: e.target.value,
                      label: e.target.value,
                    },
                  });

                  setError("");
                }
              }}
              styles={customStyles}
              placeholder={"Furnizor"}
              formatCreateLabel={() => null || undefined}
              noOptionsMessage={() => null}
              isClearable
              isLoading={false}
              isDisabled={false}
              onBlurResetsInput={false}
              onInputChange={(value) => {
                let list = locations.filter(
                  (location) =>
                    location.label &&
                    location.label.toLowerCase().includes(value.toLowerCase())
                );
                if (list.length === 0) {
                  setLucrare({ ...lucrare, locatie: null });
                  setIsRed(true);
                } else setIsRed(false);
              }}
              onChange={(value) => {
                setLucrare({ ...lucrare, locatie: value });
                setError("");
              }}
              onCreateOption={(value) => {
                setLucrare({ ...lucrare, locatie: { value, label: value } });
                setLocation([...locations, { value, label: value }]);
                setError("");
              }}
              options={locations}
              value={lucrare && lucrare.locatie}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-3">
          <ButtonComponent
            variant="success"
            text="Comandat"
            onClick={comandat}
            disabled={blocking}
          />
        </Col>
      </Row>
    </>
  );
};

export default ComandaModal;
